'use strict';

import TopLeftMenuButton from 'matrix-react-sdk/lib/components/structures/TopLeftMenuButton';
import * as ContextualMenu from 'matrix-react-sdk/lib/components/structures/ContextualMenu';
import GgTopLeftMenu from '../views/context_menus/GgTopLeftMenu';


export default class GgTopLeftMenuButton extends TopLeftMenuButton {
  static replaces = 'TopLeftMenuButton';


  constructor(){
  	super();

  	this.onToggleMenu = this.onToggleMenu.bind(this);
  }

  onToggleMenu(e) {
    e.preventDefault();
    e.stopPropagation();

    const elementRect = e.currentTarget.getBoundingClientRect();
    const x = elementRect.left;
    const y = elementRect.top + elementRect.height;

    ContextualMenu.createMenu(GgTopLeftMenu, {
      chevronFace: "none",
      left: x,
      top: y,
      onFinished: () => {
        this.setState({ menuDisplayed: false });
      },
    });
    this.setState({ menuDisplayed: true });
  }
}