var olm_exports = {};
var onInitSuccess;
var onInitFail;

var Module = (function() {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  return (
function(Module) {
  Module = Module || {};

var a;a||(a=typeof Module !== 'undefined' ? Module : {});var aa;if("undefined"!==typeof window)aa=function(b){window.crypto.getRandomValues(b)};else if(module.exports){var ba=require("crypto");aa=function(b){var c=ba.randomBytes(b.length);b.set(c)};process=global.process}else throw Error("Cannot find global to attach library to");if("undefined"!==typeof OLM_OPTIONS)for(var ca in OLM_OPTIONS)OLM_OPTIONS.hasOwnProperty(ca)&&(a[ca]=OLM_OPTIONS[ca]);
a.onRuntimeInitialized=function(){g=a._olm_error();olm_exports.PRIVATE_KEY_LENGTH=a._olm_pk_private_key_length();onInitSuccess&&onInitSuccess()};a.onAbort=function(b){onInitFail&&onInitFail(b)};var da={},h;for(h in a)a.hasOwnProperty(h)&&(da[h]=a[h]);a.arguments=[];a.thisProgram="./this.program";a.quit=function(b,c){throw c;};a.preRun=[];a.postRun=[];var m=!1,n=!1,ea=!1,fa=!1;m="object"===typeof window;n="function"===typeof importScripts;
ea="object"===typeof process&&"function"===typeof require&&!m&&!n;fa=!m&&!ea&&!n;var p="";function ha(b){return a.locateFile?a.locateFile(b,p):p+b}
if(ea){p=__dirname+"/";var ia,ja;a.read=function(b,c){ia||(ia=require("fs"));ja||(ja=require("path"));b=ja.normalize(b);b=ia.readFileSync(b);return c?b:b.toString()};a.readBinary=function(b){b=a.read(b,!0);b.buffer||(b=new Uint8Array(b));assert(b.buffer);return b};1<process.argv.length&&(a.thisProgram=process.argv[1].replace(/\\/g,"/"));a.arguments=process.argv.slice(2);process.on("uncaughtException",function(b){throw b;});process.on("unhandledRejection",function(){process.exit(1)});a.quit=function(b){process.exit(b)};
a.inspect=function(){return"[Emscripten Module object]"}}else if(fa)"undefined"!=typeof read&&(a.read=function(b){return read(b)}),a.readBinary=function(b){if("function"===typeof readbuffer)return new Uint8Array(readbuffer(b));b=read(b,"binary");assert("object"===typeof b);return b},"undefined"!=typeof scriptArgs?a.arguments=scriptArgs:"undefined"!=typeof arguments&&(a.arguments=arguments),"function"===typeof quit&&(a.quit=function(b){quit(b)});else if(m||n)m?document.currentScript&&(p=document.currentScript.src):
p=self.location.href,_scriptDir&&(p=_scriptDir),0!==p.indexOf("blob:")?p=p.substr(0,p.lastIndexOf("/")+1):p="",a.read=function(b){var c=new XMLHttpRequest;c.open("GET",b,!1);c.send(null);return c.responseText},n&&(a.readBinary=function(b){var c=new XMLHttpRequest;c.open("GET",b,!1);c.responseType="arraybuffer";c.send(null);return new Uint8Array(c.response)}),a.readAsync=function(b,c,d){var e=new XMLHttpRequest;e.open("GET",b,!0);e.responseType="arraybuffer";e.onload=function(){200==e.status||0==e.status&&
e.response?c(e.response):d()};e.onerror=d;e.send(null)},a.setWindowTitle=function(b){document.title=b};var ka=a.print||("undefined"!==typeof console?console.log.bind(console):"undefined"!==typeof print?print:null),r=a.printErr||("undefined"!==typeof printErr?printErr:"undefined"!==typeof console&&console.warn.bind(console)||ka);for(h in da)da.hasOwnProperty(h)&&(a[h]=da[h]);da=void 0;function la(b){var c=t;t=t+b+15&-16;return c}
function ma(b){var c=u[v>>2];b=c+b+15&-16;u[v>>2]=b;if(b=b>=w)na(),b=!0;return b?(u[v>>2]=c,0):c}function oa(b){var c;c||(c=16);return Math.ceil(b/c)*c}var pa={"f64-rem":function(b,c){return b%c},"debugger":function(){debugger}},qa=!1;function assert(b,c){b||x("Assertion failed: "+c)}
function y(b){var c="i8";"*"===c.charAt(c.length-1)&&(c="i32");switch(c){case "i1":z[b>>0]=0;break;case "i8":z[b>>0]=0;break;case "i16":ra[b>>1]=0;break;case "i32":u[b>>2]=0;break;case "i64":tempI64=[0,(tempDouble=0,1<=+sa(tempDouble)?0<tempDouble?(ta(+ua(tempDouble/4294967296),4294967295)|0)>>>0:~~+va((tempDouble-+(~~tempDouble>>>0))/4294967296)>>>0:0)];u[b>>2]=tempI64[0];u[b+4>>2]=tempI64[1];break;case "float":wa[b>>2]=0;break;case "double":xa[b>>3]=0;break;default:x("invalid type for setValue: "+
c)}}function ya(b,c){c=c||"i8";"*"===c.charAt(c.length-1)&&(c="i32");switch(c){case "i1":return z[b>>0];case "i8":return z[b>>0];case "i16":return ra[b>>1];case "i32":return u[b>>2];case "i64":return u[b>>2];case "float":return wa[b>>2];case "double":return xa[b>>3];default:x("invalid type for getValue: "+c)}return null}
function B(b){var c=a.ALLOC_STACK;if("number"===typeof b){var d=!0;var e=b}else d=!1,e=b.length;c=4==c?f:["function"===typeof za?za:la,Aa,la,ma][void 0===c?2:c](Math.max(e,1));if(d){var f=c;assert(0==(c&3));for(b=c+(e&-4);f<b;f+=4)u[f>>2]=0;for(b=c+e;f<b;)z[f++>>0]=0;return c}b.subarray||b.slice?C.set(b,c):C.set(new Uint8Array(b),c);return c}
function D(b){var c;if(0===c||!b)return"";for(var d=0,e,f=0;;){e=C[b+f>>0];d|=e;if(0==e&&!c)break;f++;if(c&&f==c)break}c||(c=f);e="";if(128>d){for(;0<c;)d=String.fromCharCode.apply(String,C.subarray(b,b+Math.min(c,1024))),e=e?e+d:d,b+=1024,c-=1024;return e}return E(b)}var Ba="undefined"!==typeof TextDecoder?new TextDecoder("utf8"):void 0;
function E(b){for(var c=C,d=b;c[d];)++d;if(16<d-b&&c.subarray&&Ba)return Ba.decode(c.subarray(b,d));for(d="";;){var e=c[b++];if(!e)return d;if(e&128){var f=c[b++]&63;if(192==(e&224))d+=String.fromCharCode((e&31)<<6|f);else{var l=c[b++]&63;if(224==(e&240))e=(e&15)<<12|f<<6|l;else{var k=c[b++]&63;if(240==(e&248))e=(e&7)<<18|f<<12|l<<6|k;else{var q=c[b++]&63;if(248==(e&252))e=(e&3)<<24|f<<18|l<<12|k<<6|q;else{var I=c[b++]&63;e=(e&1)<<30|f<<24|l<<18|k<<12|q<<6|I}}}65536>e?d+=String.fromCharCode(e):(e-=
65536,d+=String.fromCharCode(55296|e>>10,56320|e&1023))}}else d+=String.fromCharCode(e)}}
function Ca(b,c,d,e){if(!(0<e))return 0;var f=d;e=d+e-1;for(var l=0;l<b.length;++l){var k=b.charCodeAt(l);if(55296<=k&&57343>=k){var q=b.charCodeAt(++l);k=65536+((k&1023)<<10)|q&1023}if(127>=k){if(d>=e)break;c[d++]=k}else{if(2047>=k){if(d+1>=e)break;c[d++]=192|k>>6}else{if(65535>=k){if(d+2>=e)break;c[d++]=224|k>>12}else{if(2097151>=k){if(d+3>=e)break;c[d++]=240|k>>18}else{if(67108863>=k){if(d+4>=e)break;c[d++]=248|k>>24}else{if(d+5>=e)break;c[d++]=252|k>>30;c[d++]=128|k>>24&63}c[d++]=128|k>>18&63}c[d++]=
128|k>>12&63}c[d++]=128|k>>6&63}c[d++]=128|k&63}}c[d]=0;return d-f}function Da(b){for(var c=0,d=0;d<b.length;++d){var e=b.charCodeAt(d);55296<=e&&57343>=e&&(e=65536+((e&1023)<<10)|b.charCodeAt(++d)&1023);127>=e?++c:c=2047>=e?c+2:65535>=e?c+3:2097151>=e?c+4:67108863>=e?c+5:c+6}return c}"undefined"!==typeof TextDecoder&&new TextDecoder("utf-16le");var buffer,z,C,ra,u,wa,xa;
function Ea(){a.HEAP8=z=new Int8Array(buffer);a.HEAP16=ra=new Int16Array(buffer);a.HEAP32=u=new Int32Array(buffer);a.HEAPU8=C=new Uint8Array(buffer);a.HEAPU16=new Uint16Array(buffer);a.HEAPU32=new Uint32Array(buffer);a.HEAPF32=wa=new Float32Array(buffer);a.HEAPF64=xa=new Float64Array(buffer)}var Fa,t,Ga,Ha,Ia,Ja,v;Fa=t=Ga=Ha=Ia=Ja=v=0;
function na(){x("Cannot enlarge memory arrays. Either (1) compile with  -s TOTAL_MEMORY=X  with X higher than the current value "+w+", (2) compile with  -s ALLOW_MEMORY_GROWTH=1  which allows increasing the size at runtime, or (3) if you want malloc to return NULL (0) instead of this abort, compile with  -s ABORTING_MALLOC=0 ")}var Ka=a.TOTAL_STACK||65536,w=a.TOTAL_MEMORY||262144;w<Ka&&r("TOTAL_MEMORY should be larger than TOTAL_STACK, was "+w+"! (TOTAL_STACK="+Ka+")");
a.buffer?buffer=a.buffer:("object"===typeof WebAssembly&&"function"===typeof WebAssembly.Memory?(a.wasmMemory=new WebAssembly.Memory({initial:w/65536,maximum:w/65536}),buffer=a.wasmMemory.buffer):buffer=new ArrayBuffer(w),a.buffer=buffer);Ea();function La(b){for(;0<b.length;){var c=b.shift();if("function"==typeof c)c();else{var d=c.h;"number"===typeof d?void 0===c.c?a.dynCall_v(d):a.dynCall_vi(d,c.c):d(void 0===c.c?null:c.c)}}}var Ma=[],Na=[],Oa=[],Pa=[],Qa=!1;
function Sa(){var b=a.preRun.shift();Ma.unshift(b)}function Ta(b,c){for(var d=0;d<b.length;++d)z[c++>>0]=b.charCodeAt(d)}var sa=Math.abs,va=Math.ceil,ua=Math.floor,ta=Math.min,F=0,Ua=null,Va=null;a.preloadedImages={};a.preloadedAudios={};function Wa(b){return String.prototype.startsWith?b.startsWith("data:application/octet-stream;base64,"):0===b.indexOf("data:application/octet-stream;base64,")}
(function(){function b(){try{if(a.wasmBinary)return new Uint8Array(a.wasmBinary);if(a.readBinary)return a.readBinary(f);throw"both async and sync fetching of the wasm failed";}catch(A){x(A)}}function c(){return a.wasmBinary||!m&&!n||"function"!==typeof fetch?new Promise(function(c){c(b())}):fetch(f,{credentials:"same-origin"}).then(function(b){if(!b.ok)throw"failed to load wasm binary file at '"+f+"'";return b.arrayBuffer()}).catch(function(){return b()})}function d(b){function d(b){q=b.exports;if(q.memory){b=
q.memory;var c=a.buffer;b.byteLength<c.byteLength&&r("the new buffer in mergeMemory is smaller than the previous one. in native wasm, we should grow memory here");c=new Int8Array(c);(new Int8Array(b)).set(c);a.buffer=buffer=b;Ea()}a.asm=q;a.usingWasm=!0;F--;a.monitorRunDependencies&&a.monitorRunDependencies(F);0==F&&(null!==Ua&&(clearInterval(Ua),Ua=null),Va&&(b=Va,Va=null,b()))}function e(b){d(b.instance)}function l(b){c().then(function(b){return WebAssembly.instantiate(b,k)}).then(b).catch(function(b){r("failed to asynchronously prepare wasm: "+
b);x(b)})}if("object"!==typeof WebAssembly)return r("no native wasm support detected"),!1;if(!(a.wasmMemory instanceof WebAssembly.Memory))return r("no native wasm Memory in use"),!1;b.memory=a.wasmMemory;k.global={NaN:NaN,Infinity:Infinity};k["global.Math"]=Math;k.env=b;F++;a.monitorRunDependencies&&a.monitorRunDependencies(F);if(a.instantiateWasm)try{return a.instantiateWasm(k,d)}catch(cb){return r("Module.instantiateWasm callback failed with error: "+cb),!1}a.wasmBinary||"function"!==typeof WebAssembly.instantiateStreaming||
Wa(f)||"function"!==typeof fetch?l(e):WebAssembly.instantiateStreaming(fetch(f,{credentials:"same-origin"}),k).then(e).catch(function(b){r("wasm streaming compile failed: "+b);r("falling back to ArrayBuffer instantiation");l(e)});return{}}var e="olm.wast",f="olm.wasm",l="olm.temp.asm.js";Wa(e)||(e=ha(e));Wa(f)||(f=ha(f));Wa(l)||(l=ha(l));var k={global:null,env:null,asm2wasm:pa,parent:a},q=null;a.asmPreload=a.asm;var I=a.reallocBuffer;a.reallocBuffer=function(b){if("asmjs"===O)var c=I(b);else a:{var d=
a.usingWasm?65536:16777216;0<b%d&&(b+=d-b%d);d=a.buffer.byteLength;if(a.usingWasm)try{c=-1!==a.wasmMemory.grow((b-d)/65536)?a.buffer=a.wasmMemory.buffer:null;break a}catch(gb){c=null;break a}c=void 0}return c};var O="";a.asm=function(b,c){if(!c.table){b=a.wasmTableSize;void 0===b&&(b=1024);var e=a.wasmMaxTableSize;c.table="object"===typeof WebAssembly&&"function"===typeof WebAssembly.Table?void 0!==e?new WebAssembly.Table({initial:b,maximum:e,element:"anyfunc"}):new WebAssembly.Table({initial:b,element:"anyfunc"}):
Array(b);a.wasmTable=c.table}c.memoryBase||(c.memoryBase=a.STATIC_BASE);c.tableBase||(c.tableBase=0);c=d(c);assert(c,"no binaryen method succeeded.");return c}})();Fa=1024;t=Fa+37168;Na.push();a.STATIC_BASE=Fa;a.STATIC_BUMP=37168;t+=16;v=la(4);Ga=Ha=oa(t);Ia=Ga+Ka;Ja=oa(Ia);u[v>>2]=Ja;function G(b){var c=Array(Da(b)+1);b=Ca(b,c,0,c.length);c.length=b;return c}a.wasmTableSize=10;a.wasmMaxTableSize=10;a.f={};
a.g={abort:x,enlargeMemory:function(){na()},getTotalMemory:function(){return w},abortOnCannotGrowMemory:na,___setErrNo:function(b){a.___errno_location&&(u[a.___errno_location()>>2]=b);return b},_emscripten_memcpy_big:function(b,c,d){C.set(C.subarray(c,c+d),b);return b},DYNAMICTOP_PTR:v,STACKTOP:Ha};var Xa=a.asm(a.f,a.g,buffer);a.asm=Xa;a._free=function(){return a.asm._free.apply(null,arguments)};var za=a._malloc=function(){return a.asm._malloc.apply(null,arguments)};
a._olm_account=function(){return a.asm._olm_account.apply(null,arguments)};a._olm_account_generate_one_time_keys=function(){return a.asm._olm_account_generate_one_time_keys.apply(null,arguments)};a._olm_account_generate_one_time_keys_random_length=function(){return a.asm._olm_account_generate_one_time_keys_random_length.apply(null,arguments)};a._olm_account_identity_keys=function(){return a.asm._olm_account_identity_keys.apply(null,arguments)};
a._olm_account_identity_keys_length=function(){return a.asm._olm_account_identity_keys_length.apply(null,arguments)};a._olm_account_last_error=function(){return a.asm._olm_account_last_error.apply(null,arguments)};a._olm_account_mark_keys_as_published=function(){return a.asm._olm_account_mark_keys_as_published.apply(null,arguments)};a._olm_account_max_number_of_one_time_keys=function(){return a.asm._olm_account_max_number_of_one_time_keys.apply(null,arguments)};
a._olm_account_one_time_keys=function(){return a.asm._olm_account_one_time_keys.apply(null,arguments)};a._olm_account_one_time_keys_length=function(){return a.asm._olm_account_one_time_keys_length.apply(null,arguments)};a._olm_account_sign=function(){return a.asm._olm_account_sign.apply(null,arguments)};a._olm_account_signature_length=function(){return a.asm._olm_account_signature_length.apply(null,arguments)};a._olm_account_size=function(){return a.asm._olm_account_size.apply(null,arguments)};
a._olm_clear_account=function(){return a.asm._olm_clear_account.apply(null,arguments)};a._olm_clear_inbound_group_session=function(){return a.asm._olm_clear_inbound_group_session.apply(null,arguments)};a._olm_clear_outbound_group_session=function(){return a.asm._olm_clear_outbound_group_session.apply(null,arguments)};a._olm_clear_pk_decryption=function(){return a.asm._olm_clear_pk_decryption.apply(null,arguments)};
a._olm_clear_pk_encryption=function(){return a.asm._olm_clear_pk_encryption.apply(null,arguments)};a._olm_clear_sas=function(){return a.asm._olm_clear_sas.apply(null,arguments)};a._olm_clear_session=function(){return a.asm._olm_clear_session.apply(null,arguments)};a._olm_clear_utility=function(){return a.asm._olm_clear_utility.apply(null,arguments)};a._olm_create_account=function(){return a.asm._olm_create_account.apply(null,arguments)};
a._olm_create_account_random_length=function(){return a.asm._olm_create_account_random_length.apply(null,arguments)};a._olm_create_inbound_session=function(){return a.asm._olm_create_inbound_session.apply(null,arguments)};a._olm_create_inbound_session_from=function(){return a.asm._olm_create_inbound_session_from.apply(null,arguments)};a._olm_create_outbound_session=function(){return a.asm._olm_create_outbound_session.apply(null,arguments)};
a._olm_create_outbound_session_random_length=function(){return a.asm._olm_create_outbound_session_random_length.apply(null,arguments)};a._olm_create_sas=function(){return a.asm._olm_create_sas.apply(null,arguments)};a._olm_create_sas_random_length=function(){return a.asm._olm_create_sas_random_length.apply(null,arguments)};a._olm_decrypt=function(){return a.asm._olm_decrypt.apply(null,arguments)};
a._olm_decrypt_max_plaintext_length=function(){return a.asm._olm_decrypt_max_plaintext_length.apply(null,arguments)};a._olm_ed25519_verify=function(){return a.asm._olm_ed25519_verify.apply(null,arguments)};a._olm_encrypt=function(){return a.asm._olm_encrypt.apply(null,arguments)};a._olm_encrypt_message_length=function(){return a.asm._olm_encrypt_message_length.apply(null,arguments)};a._olm_encrypt_message_type=function(){return a.asm._olm_encrypt_message_type.apply(null,arguments)};
a._olm_encrypt_random_length=function(){return a.asm._olm_encrypt_random_length.apply(null,arguments)};a._olm_error=function(){return a.asm._olm_error.apply(null,arguments)};a._olm_export_inbound_group_session=function(){return a.asm._olm_export_inbound_group_session.apply(null,arguments)};a._olm_export_inbound_group_session_length=function(){return a.asm._olm_export_inbound_group_session_length.apply(null,arguments)};
a._olm_get_library_version=function(){return a.asm._olm_get_library_version.apply(null,arguments)};a._olm_group_decrypt=function(){return a.asm._olm_group_decrypt.apply(null,arguments)};a._olm_group_decrypt_max_plaintext_length=function(){return a.asm._olm_group_decrypt_max_plaintext_length.apply(null,arguments)};a._olm_group_encrypt=function(){return a.asm._olm_group_encrypt.apply(null,arguments)};
a._olm_group_encrypt_message_length=function(){return a.asm._olm_group_encrypt_message_length.apply(null,arguments)};a._olm_import_inbound_group_session=function(){return a.asm._olm_import_inbound_group_session.apply(null,arguments)};a._olm_inbound_group_session=function(){return a.asm._olm_inbound_group_session.apply(null,arguments)};a._olm_inbound_group_session_first_known_index=function(){return a.asm._olm_inbound_group_session_first_known_index.apply(null,arguments)};
a._olm_inbound_group_session_id=function(){return a.asm._olm_inbound_group_session_id.apply(null,arguments)};a._olm_inbound_group_session_id_length=function(){return a.asm._olm_inbound_group_session_id_length.apply(null,arguments)};a._olm_inbound_group_session_is_verified=function(){return a.asm._olm_inbound_group_session_is_verified.apply(null,arguments)};a._olm_inbound_group_session_last_error=function(){return a.asm._olm_inbound_group_session_last_error.apply(null,arguments)};
a._olm_inbound_group_session_size=function(){return a.asm._olm_inbound_group_session_size.apply(null,arguments)};a._olm_init_inbound_group_session=function(){return a.asm._olm_init_inbound_group_session.apply(null,arguments)};a._olm_init_outbound_group_session=function(){return a.asm._olm_init_outbound_group_session.apply(null,arguments)};a._olm_init_outbound_group_session_random_length=function(){return a.asm._olm_init_outbound_group_session_random_length.apply(null,arguments)};
a._olm_matches_inbound_session=function(){return a.asm._olm_matches_inbound_session.apply(null,arguments)};a._olm_matches_inbound_session_from=function(){return a.asm._olm_matches_inbound_session_from.apply(null,arguments)};a._olm_outbound_group_session=function(){return a.asm._olm_outbound_group_session.apply(null,arguments)};a._olm_outbound_group_session_id=function(){return a.asm._olm_outbound_group_session_id.apply(null,arguments)};
a._olm_outbound_group_session_id_length=function(){return a.asm._olm_outbound_group_session_id_length.apply(null,arguments)};a._olm_outbound_group_session_key=function(){return a.asm._olm_outbound_group_session_key.apply(null,arguments)};a._olm_outbound_group_session_key_length=function(){return a.asm._olm_outbound_group_session_key_length.apply(null,arguments)};a._olm_outbound_group_session_last_error=function(){return a.asm._olm_outbound_group_session_last_error.apply(null,arguments)};
a._olm_outbound_group_session_message_index=function(){return a.asm._olm_outbound_group_session_message_index.apply(null,arguments)};a._olm_outbound_group_session_size=function(){return a.asm._olm_outbound_group_session_size.apply(null,arguments)};a._olm_pickle_account=function(){return a.asm._olm_pickle_account.apply(null,arguments)};a._olm_pickle_account_length=function(){return a.asm._olm_pickle_account_length.apply(null,arguments)};
a._olm_pickle_inbound_group_session=function(){return a.asm._olm_pickle_inbound_group_session.apply(null,arguments)};a._olm_pickle_inbound_group_session_length=function(){return a.asm._olm_pickle_inbound_group_session_length.apply(null,arguments)};a._olm_pickle_outbound_group_session=function(){return a.asm._olm_pickle_outbound_group_session.apply(null,arguments)};a._olm_pickle_outbound_group_session_length=function(){return a.asm._olm_pickle_outbound_group_session_length.apply(null,arguments)};
a._olm_pickle_pk_decryption=function(){return a.asm._olm_pickle_pk_decryption.apply(null,arguments)};a._olm_pickle_pk_decryption_length=function(){return a.asm._olm_pickle_pk_decryption_length.apply(null,arguments)};a._olm_pickle_session=function(){return a.asm._olm_pickle_session.apply(null,arguments)};a._olm_pickle_session_length=function(){return a.asm._olm_pickle_session_length.apply(null,arguments)};a._olm_pk_ciphertext_length=function(){return a.asm._olm_pk_ciphertext_length.apply(null,arguments)};
a._olm_pk_decrypt=function(){return a.asm._olm_pk_decrypt.apply(null,arguments)};a._olm_pk_decryption=function(){return a.asm._olm_pk_decryption.apply(null,arguments)};a._olm_pk_decryption_last_error=function(){return a.asm._olm_pk_decryption_last_error.apply(null,arguments)};a._olm_pk_decryption_size=function(){return a.asm._olm_pk_decryption_size.apply(null,arguments)};a._olm_pk_encrypt=function(){return a.asm._olm_pk_encrypt.apply(null,arguments)};
a._olm_pk_encrypt_random_length=function(){return a.asm._olm_pk_encrypt_random_length.apply(null,arguments)};a._olm_pk_encryption=function(){return a.asm._olm_pk_encryption.apply(null,arguments)};a._olm_pk_encryption_last_error=function(){return a.asm._olm_pk_encryption_last_error.apply(null,arguments)};a._olm_pk_encryption_set_recipient_key=function(){return a.asm._olm_pk_encryption_set_recipient_key.apply(null,arguments)};
a._olm_pk_encryption_size=function(){return a.asm._olm_pk_encryption_size.apply(null,arguments)};a._olm_pk_generate_key=function(){return a.asm._olm_pk_generate_key.apply(null,arguments)};a._olm_pk_generate_key_random_length=function(){return a.asm._olm_pk_generate_key_random_length.apply(null,arguments)};a._olm_pk_get_private_key=function(){return a.asm._olm_pk_get_private_key.apply(null,arguments)};a._olm_pk_key_from_private=function(){return a.asm._olm_pk_key_from_private.apply(null,arguments)};
a._olm_pk_key_length=function(){return a.asm._olm_pk_key_length.apply(null,arguments)};a._olm_pk_mac_length=function(){return a.asm._olm_pk_mac_length.apply(null,arguments)};a._olm_pk_max_plaintext_length=function(){return a.asm._olm_pk_max_plaintext_length.apply(null,arguments)};a._olm_pk_private_key_length=function(){return a.asm._olm_pk_private_key_length.apply(null,arguments)};a._olm_remove_one_time_keys=function(){return a.asm._olm_remove_one_time_keys.apply(null,arguments)};
a._olm_sas=function(){return a.asm._olm_sas.apply(null,arguments)};a._olm_sas_calculate_mac=function(){return a.asm._olm_sas_calculate_mac.apply(null,arguments)};a._olm_sas_generate_bytes=function(){return a.asm._olm_sas_generate_bytes.apply(null,arguments)};a._olm_sas_get_pubkey=function(){return a.asm._olm_sas_get_pubkey.apply(null,arguments)};a._olm_sas_last_error=function(){return a.asm._olm_sas_last_error.apply(null,arguments)};
a._olm_sas_mac_length=function(){return a.asm._olm_sas_mac_length.apply(null,arguments)};a._olm_sas_pubkey_length=function(){return a.asm._olm_sas_pubkey_length.apply(null,arguments)};a._olm_sas_set_their_key=function(){return a.asm._olm_sas_set_their_key.apply(null,arguments)};a._olm_sas_size=function(){return a.asm._olm_sas_size.apply(null,arguments)};a._olm_session=function(){return a.asm._olm_session.apply(null,arguments)};
a._olm_session_has_received_message=function(){return a.asm._olm_session_has_received_message.apply(null,arguments)};a._olm_session_id=function(){return a.asm._olm_session_id.apply(null,arguments)};a._olm_session_id_length=function(){return a.asm._olm_session_id_length.apply(null,arguments)};a._olm_session_last_error=function(){return a.asm._olm_session_last_error.apply(null,arguments)};a._olm_session_size=function(){return a.asm._olm_session_size.apply(null,arguments)};
a._olm_sha256=function(){return a.asm._olm_sha256.apply(null,arguments)};a._olm_sha256_length=function(){return a.asm._olm_sha256_length.apply(null,arguments)};a._olm_unpickle_account=function(){return a.asm._olm_unpickle_account.apply(null,arguments)};a._olm_unpickle_inbound_group_session=function(){return a.asm._olm_unpickle_inbound_group_session.apply(null,arguments)};a._olm_unpickle_outbound_group_session=function(){return a.asm._olm_unpickle_outbound_group_session.apply(null,arguments)};
a._olm_unpickle_pk_decryption=function(){return a.asm._olm_unpickle_pk_decryption.apply(null,arguments)};a._olm_unpickle_session=function(){return a.asm._olm_unpickle_session.apply(null,arguments)};a._olm_utility=function(){return a.asm._olm_utility.apply(null,arguments)};a._olm_utility_last_error=function(){return a.asm._olm_utility_last_error.apply(null,arguments)};a._olm_utility_size=function(){return a.asm._olm_utility_size.apply(null,arguments)};
var Aa=a.stackAlloc=function(){return a.asm.stackAlloc.apply(null,arguments)},Ya=a.stackRestore=function(){return a.asm.stackRestore.apply(null,arguments)},Za=a.stackSave=function(){return a.asm.stackSave.apply(null,arguments)};a.asm=Xa;a.ALLOC_STACK=1;a.then=function(b){if(a.calledRun)b(a);else{var c=a.onRuntimeInitialized;a.onRuntimeInitialized=function(){c&&c();b(a)}}return a};Va=function $a(){a.calledRun||ab();a.calledRun||(Va=$a)};
function ab(){function b(){if(!a.calledRun&&(a.calledRun=!0,!qa)){Qa||(Qa=!0,La(Na));La(Oa);if(a.onRuntimeInitialized)a.onRuntimeInitialized();if(a.postRun)for("function"==typeof a.postRun&&(a.postRun=[a.postRun]);a.postRun.length;){var b=a.postRun.shift();Pa.unshift(b)}La(Pa)}}if(!(0<F)){if(a.preRun)for("function"==typeof a.preRun&&(a.preRun=[a.preRun]);a.preRun.length;)Sa();La(Ma);0<F||a.calledRun||(a.setStatus?(a.setStatus("Running..."),setTimeout(function(){setTimeout(function(){a.setStatus("")},
1);b()},1)):b())}}a.run=ab;function x(b){if(a.onAbort)a.onAbort(b);void 0!==b?(ka(b),r(b),b=JSON.stringify(b)):b="";qa=!0;throw"abort("+b+"). Build with -s ASSERTIONS=1 for more info.";}a.abort=x;if(a.preInit)for("function"==typeof a.preInit&&(a.preInit=[a.preInit]);0<a.preInit.length;)a.preInit.pop()();a.noExitRuntime=!0;ab();function H(){var b=a._olm_outbound_group_session_size();this.b=J(b);this.a=a._olm_outbound_group_session(this.b)}
function K(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=D(a._olm_outbound_group_session_last_error(arguments[0])),Error("OLM."+c);return c}}H.prototype.free=function(){a._olm_clear_outbound_group_session(this.a);L(this.a)};H.prototype.pickle=M(function(b){b=G(b);var c=K(a._olm_pickle_outbound_group_session_length)(this.a),d=B(b),e=B(c+1);try{K(a._olm_pickle_outbound_group_session)(this.a,d,b.length,e,c)}finally{for(N(d,b.length),c=0;c<b.length;c++)b[c]=0}return D(e)});
H.prototype.unpickle=M(function(b,c){b=G(b);var d=B(b);c=G(c);var e=B(c);try{K(a._olm_unpickle_outbound_group_session)(this.a,d,b.length,e,c.length)}finally{for(N(d,b.length),d=0;d<b.length;d++)b[d]=0}});H.prototype.create=M(function(){var b=K(a._olm_init_outbound_group_session_random_length)(this.a),c=P(b);K(a._olm_init_outbound_group_session)(this.a,c,b)});
H.prototype.encrypt=function(b){try{var c=Da(b);var d=K(a._olm_group_encrypt_message_length)(this.a,c);var e=J(c+1);Ca(b,C,e,c+1);var f=J(d+1);K(a._olm_group_encrypt)(this.a,e,c,f,d);y(f+d);return E(f)}finally{void 0!==e&&(N(e,c+1),L(e)),void 0!==f&&L(f)}};H.prototype.session_id=M(function(){var b=K(a._olm_outbound_group_session_id_length)(this.a),c=B(b+1);K(a._olm_outbound_group_session_id)(this.a,c,b);return D(c)});
H.prototype.session_key=M(function(){var b=K(a._olm_outbound_group_session_key_length)(this.a),c=B(b+1);K(a._olm_outbound_group_session_key)(this.a,c,b);var d=D(c);N(c,b);return d});H.prototype.message_index=function(){return K(a._olm_outbound_group_session_message_index)(this.a)};olm_exports.OutboundGroupSession=H;function Q(){var b=a._olm_inbound_group_session_size();this.b=J(b);this.a=a._olm_inbound_group_session(this.b)}
function R(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=D(a._olm_inbound_group_session_last_error(arguments[0])),Error("OLM."+c);return c}}Q.prototype.free=function(){a._olm_clear_inbound_group_session(this.a);L(this.a)};Q.prototype.pickle=M(function(b){b=G(b);var c=R(a._olm_pickle_inbound_group_session_length)(this.a),d=B(b),e=B(c+1);try{R(a._olm_pickle_inbound_group_session)(this.a,d,b.length,e,c)}finally{for(N(d,b.length),c=0;c<b.length;c++)b[c]=0}return D(e)});
Q.prototype.unpickle=M(function(b,c){b=G(b);var d=B(b);c=G(c);var e=B(c);try{R(a._olm_unpickle_inbound_group_session)(this.a,d,b.length,e,c.length)}finally{for(N(d,b.length),d=0;d<b.length;d++)b[d]=0}});Q.prototype.create=M(function(b){b=G(b);var c=B(b);try{R(a._olm_init_inbound_group_session)(this.a,c,b.length)}finally{for(N(c,b.length),c=0;c<b.length;c++)b[c]=0}});
Q.prototype.import_session=M(function(b){b=G(b);var c=B(b);try{R(a._olm_import_inbound_group_session)(this.a,c,b.length)}finally{for(N(c,b.length),c=0;c<b.length;c++)b[c]=0}});Q.prototype.decrypt=M(function(b){try{var c=J(b.length);Ta(b,c);var d=R(a._olm_group_decrypt_max_plaintext_length)(this.a,c,b.length);Ta(b,c);var e=J(d+1);var f=B(4);var l=R(a._olm_group_decrypt)(this.a,c,b.length,e,d,f);y(e+l);return{plaintext:E(e),message_index:ya(f,"i32")}}finally{void 0!==c&&L(c),void 0!==e&&(N(e,l+1),L(e))}});
Q.prototype.session_id=M(function(){var b=R(a._olm_inbound_group_session_id_length)(this.a),c=B(b+1);R(a._olm_inbound_group_session_id)(this.a,c,b);return D(c)});Q.prototype.first_known_index=M(function(){return R(a._olm_inbound_group_session_first_known_index)(this.a)});Q.prototype.export_session=M(function(b){var c=R(a._olm_export_inbound_group_session_length)(this.a),d=B(c+1);K(a._olm_export_inbound_group_session)(this.a,d,c,b);b=D(d);N(d,c);return b});olm_exports.InboundGroupSession=Q;
function bb(){var b=a._olm_pk_encryption_size();this.b=J(b);this.a=a._olm_pk_encryption(this.b)}function S(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=D(a._olm_pk_encryption_last_error(arguments[0])),Error("OLM."+c);return c}}bb.prototype.free=function(){a._olm_clear_pk_encryption(this.a);L(this.a)};bb.prototype.set_recipient_key=M(function(b){b=G(b);var c=B(b);S(a._olm_pk_encryption_set_recipient_key)(this.a,c,b.length)});
bb.prototype.encrypt=M(function(b){try{var c=Da(b);var d=J(c+1);Ca(b,C,d,c+1);var e=S(a._olm_pk_encrypt_random_length)();var f=P(e);var l=S(a._olm_pk_ciphertext_length)(this.a,c);var k=J(l+1);var q=S(a._olm_pk_mac_length)(this.a),I=B(q+1);y(I+q);var O=S(a._olm_pk_key_length)(),A=B(O+1);y(A+O);S(a._olm_pk_encrypt)(this.a,d,c,k,l,I,q,A,O,f,e);y(k+l);return{ciphertext:E(k),mac:D(I),ephemeral:D(A)}}finally{void 0!==f&&N(f,e),void 0!==d&&(N(d,c+1),L(d)),void 0!==k&&L(k)}});
function T(){var b=a._olm_pk_decryption_size();this.b=J(b);this.a=a._olm_pk_decryption(this.b)}function U(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=D(a._olm_pk_decryption_last_error(arguments[0])),Error("OLM."+c);return c}}T.prototype.free=function(){a._olm_clear_pk_decryption(this.a);L(this.a)};
T.prototype.init_with_private_key=M(function(b){var c=B(b.length);a.HEAPU8.set(b,c);var d=U(a._olm_pk_key_length)(),e=B(d+1);try{U(a._olm_pk_key_from_private)(this.a,e,d,c,b.length)}finally{N(c,b.length)}return D(e)});T.prototype.generate_key=M(function(){var b=U(a._olm_pk_private_key_length)(),c=P(b),d=U(a._olm_pk_key_length)(),e=B(d+1);try{U(a._olm_pk_key_from_private)(this.a,e,d,c,b)}finally{N(c,b)}return D(e)});
T.prototype.get_private_key=M(function(){var b=S(a._olm_pk_private_key_length)(),c=B(b);U(a._olm_pk_get_private_key)(this.a,c,b);var d=new Uint8Array(new Uint8Array(a.HEAPU8.buffer,c,b));N(c,b);return d});T.prototype.pickle=M(function(b){b=G(b);var c=U(a._olm_pickle_pk_decryption_length)(this.a),d=B(b),e=B(c+1);try{U(a._olm_pickle_pk_decryption)(this.a,d,b.length,e,c)}finally{for(N(d,b.length),c=0;c<b.length;c++)b[c]=0}return D(e)});
T.prototype.unpickle=M(function(b,c){b=G(b);var d=B(b),e=G(c),f=B(e),l=U(a._olm_pk_key_length)();c=B(l+1);try{U(a._olm_unpickle_pk_decryption)(this.a,d,b.length,f,e.length,c,l)}finally{for(N(d,b.length),d=0;d<b.length;d++)b[d]=0}return D(c)});
T.prototype.decrypt=M(function(b,c,d){try{var e=Da(d);var f=J(e+1);Ca(d,C,f,e+1);var l=G(b),k=B(l),q=G(c),I=B(q);var O=U(a._olm_pk_max_plaintext_length)(this.a,e);var A=J(O+1);var Ra=U(a._olm_pk_decrypt)(this.a,k,l.length,I,q.length,f,e,A,O);y(A+Ra);return E(A)}finally{void 0!==A&&(N(A,Ra+1),L(A)),void 0!==f&&L(f)}});function V(){var b=a._olm_sas_size(),c=a._olm_create_sas_random_length(),d=P(c);this.b=J(b);this.a=a._olm_sas(this.b);a._olm_create_sas(this.a,d,c);N(d,c)}
function db(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=D(a._olm_sas_last_error(arguments[0])),Error("OLM."+c);return c}}V.prototype.free=function(){a._olm_clear_sas(this.a);L(this.a)};V.prototype.get_pubkey=M(function(){var b=db(a._olm_sas_pubkey_length)(this.a),c=B(b+1);db(a._olm_sas_get_pubkey)(this.a,c,b);return D(c)});V.prototype.set_their_key=M(function(b){b=G(b);var c=B(b);db(a._olm_sas_set_their_key)(this.a,c,b.length)});
V.prototype.generate_bytes=M(function(b,c){b=G(b);var d=B(b),e=B(c);db(a._olm_sas_generate_bytes)(this.a,d,b.length,e,c);return new Uint8Array(new Uint8Array(a.HEAPU8.buffer,e,c))});V.prototype.calculate_mac=M(function(b,c){b=G(b);var d=B(b);c=G(c);var e=B(c),f=db(a._olm_sas_mac_length)(this.a),l=B(f+1);db(a._olm_sas_calculate_mac)(this.a,d,b.length,e,c.length,l,f);return D(l)});var J=a._malloc,L=a._free,g;function P(b){var c=B(b);b=new Uint8Array(a.HEAPU8.buffer,c,b);aa(b);return c}
function M(b){return function(){var c=Za();try{return b.apply(this,arguments)}finally{Ya(c)}}}function N(b,c){for(;0<c--;)a.HEAP8[b++]=0}function W(){var b=a._olm_account_size();this.b=J(b);this.a=a._olm_account(this.b)}function X(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=D(a._olm_account_last_error(arguments[0])),Error("OLM."+c);return c}}W.prototype.free=function(){a._olm_clear_account(this.a);L(this.a)};
W.prototype.create=M(function(){var b=X(a._olm_create_account_random_length)(this.a),c=P(b);X(a._olm_create_account)(this.a,c,b)});W.prototype.identity_keys=M(function(){var b=X(a._olm_account_identity_keys_length)(this.a),c=B(b+1);X(a._olm_account_identity_keys)(this.a,c,b);return D(c)});W.prototype.sign=M(function(b){var c=X(a._olm_account_signature_length)(this.a);b=G(b);var d=B(b),e=B(c+1);try{X(a._olm_account_sign)(this.a,d,b.length,e,c)}finally{for(N(d,b.length),c=0;c<b.length;c++)b[c]=0}return D(e)});
W.prototype.one_time_keys=M(function(){var b=X(a._olm_account_one_time_keys_length)(this.a),c=B(b+1);X(a._olm_account_one_time_keys)(this.a,c,b);return D(c)});W.prototype.mark_keys_as_published=M(function(){X(a._olm_account_mark_keys_as_published)(this.a)});W.prototype.max_number_of_one_time_keys=M(function(){return X(a._olm_account_max_number_of_one_time_keys)(this.a)});
W.prototype.generate_one_time_keys=M(function(b){var c=X(a._olm_account_generate_one_time_keys_random_length)(this.a,b),d=P(c);X(a._olm_account_generate_one_time_keys)(this.a,b,d,c)});W.prototype.remove_one_time_keys=M(function(b){X(a._olm_remove_one_time_keys)(this.a,b.a)});W.prototype.pickle=M(function(b){b=G(b);var c=X(a._olm_pickle_account_length)(this.a),d=B(b),e=B(c+1);try{X(a._olm_pickle_account)(this.a,d,b.length,e,c)}finally{for(N(d,b.length),c=0;c<b.length;c++)b[c]=0}return D(e)});
W.prototype.unpickle=M(function(b,c){b=G(b);var d=B(b);c=G(c);var e=B(c);try{X(a._olm_unpickle_account)(this.a,d,b.length,e,c.length)}finally{for(N(d,b.length),d=0;d<b.length;d++)b[d]=0}});function Y(){var b=a._olm_session_size();this.b=J(b);this.a=a._olm_session(this.b)}function Z(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=D(a._olm_session_last_error(arguments[0])),Error("OLM."+c);return c}}Y.prototype.free=function(){a._olm_clear_session(this.a);L(this.a)};
Y.prototype.pickle=M(function(b){b=G(b);var c=Z(a._olm_pickle_session_length)(this.a),d=B(b),e=B(c+1);try{Z(a._olm_pickle_session)(this.a,d,b.length,e,c)}finally{for(N(d,b.length),c=0;c<b.length;c++)b[c]=0}return D(e)});Y.prototype.unpickle=M(function(b,c){b=G(b);var d=B(b);c=G(c);var e=B(c);try{Z(a._olm_unpickle_session)(this.a,d,b.length,e,c.length)}finally{for(N(d,b.length),d=0;d<b.length;d++)b[d]=0}});
Y.prototype.create_outbound=M(function(b,c,d){var e=Z(a._olm_create_outbound_session_random_length)(this.a),f=P(e);c=G(c);d=G(d);var l=B(c),k=B(d);try{Z(a._olm_create_outbound_session)(this.a,b.a,l,c.length,k,d.length,f,e)}finally{N(f,e)}});Y.prototype.create_inbound=M(function(b,c){c=G(c);var d=B(c);try{Z(a._olm_create_inbound_session)(this.a,b.a,d,c.length)}finally{for(N(d,c.length),b=0;b<c.length;b++)c[b]=0}});
Y.prototype.create_inbound_from=M(function(b,c,d){c=G(c);var e=B(c);d=G(d);var f=B(d);try{Z(a._olm_create_inbound_session_from)(this.a,b.a,e,c.length,f,d.length)}finally{for(N(f,d.length),b=0;b<d.length;b++)d[b]=0}});Y.prototype.session_id=M(function(){var b=Z(a._olm_session_id_length)(this.a),c=B(b+1);Z(a._olm_session_id)(this.a,c,b);return D(c)});Y.prototype.has_received_message=function(){return Z(a._olm_session_has_received_message)(this.a)?!0:!1};
Y.prototype.matches_inbound=M(function(b){b=G(b);var c=B(b);return Z(a._olm_matches_inbound_session)(this.a,c,b.length)?!0:!1});Y.prototype.matches_inbound_from=M(function(b,c){b=G(b);var d=B(b);c=G(c);var e=B(c);return Z(a._olm_matches_inbound_session_from)(this.a,d,b.length,e,c.length)?!0:!1});
Y.prototype.encrypt=M(function(b){try{var c=Z(a._olm_encrypt_random_length)(this.a);var d=Z(a._olm_encrypt_message_type)(this.a);var e=Da(b);var f=Z(a._olm_encrypt_message_length)(this.a,e);var l=P(c);var k=J(e+1);Ca(b,C,k,e+1);var q=J(f+1);Z(a._olm_encrypt)(this.a,k,e,l,c,q,f);y(q+f);return{type:d,body:E(q)}}finally{void 0!==l&&N(l,c),void 0!==k&&(N(k,e+1),L(k)),void 0!==q&&L(q)}});
Y.prototype.decrypt=M(function(b,c){try{var d=J(c.length);Ta(c,d);var e=Z(a._olm_decrypt_max_plaintext_length)(this.a,b,d,c.length);Ta(c,d);var f=J(e+1);var l=Z(a._olm_decrypt)(this.a,b,d,c.length,f,e);y(f+l);return E(f)}finally{void 0!==d&&L(d),void 0!==f&&(N(f,e+1),L(f))}});function eb(){var b=a._olm_utility_size();this.b=J(b);this.a=a._olm_utility(this.b)}
function fb(b){return function(){var c=b.apply(this,arguments);if(c===g)throw c=D(a._olm_utility_last_error(arguments[0])),Error("OLM."+c);return c}}eb.prototype.free=function(){a._olm_clear_utility(this.a);L(this.a)};eb.prototype.sha256=M(function(b){var c=fb(a._olm_sha256_length)(this.a);b=G(b);var d=B(b),e=B(c+1);try{fb(a._olm_sha256)(this.a,d,b.length,e,c)}finally{for(N(d,b.length),c=0;c<b.length;c++)b[c]=0}return D(e)});
eb.prototype.ed25519_verify=M(function(b,c,d){b=G(b);var e=B(b);c=G(c);var f=B(c);d=G(d);var l=B(d);try{fb(a._olm_ed25519_verify)(this.a,e,b.length,f,c.length,l,d.length)}finally{for(N(f,c.length),b=0;b<c.length;b++)c[b]=0}});olm_exports.Account=W;olm_exports.Session=Y;olm_exports.Utility=eb;olm_exports.PkEncryption=bb;olm_exports.PkDecryption=T;olm_exports.SAS=V;olm_exports.get_library_version=M(function(){var b=B(3);a._olm_get_library_version(b,b+1,b+2);return[ya(b,"i8"),ya(b+1,"i8"),ya(b+2,"i8")]});


  return Module;
}
);
})();
if (typeof exports === 'object' && typeof module === 'object')
    module.exports = Module;
  else if (typeof define === 'function' && define['amd'])
    define([], function() { return Module; });
  else if (typeof exports === 'object')
    exports["Module"] = Module;
  var olmInitPromise;

olm_exports['init'] = function(opts) {
    if (olmInitPromise) return olmInitPromise;

    if (opts) OLM_OPTIONS = opts;

    olmInitPromise = new Promise(function(resolve, reject) {
        onInitSuccess = function() {
            resolve();
        };
        onInitFail = function(err) {
            reject(err);
        };
        Module();
    });
    return olmInitPromise;
};

if (typeof(window) !== 'undefined') {
    // We've been imported directly into a browser. Define the global 'Olm' object.
    // (we do this even if module.exports was defined, because it's useful to have
    // Olm in the global scope for browserified and webpacked apps.)
    window["Olm"] = olm_exports;
}

if (typeof module === 'object') {
    // Emscripten sets the module exports to be its module
    // with wrapped c functions. Clobber it with our higher
    // level wrapper class.
    module.exports = olm_exports;
}
