'use strict';

import dis from 'matrix-react-sdk/lib/dispatcher';
import {TopLeftMenu} from 'matrix-react-sdk/lib/components/views/context_menus/TopLeftMenu';


export default class GgTopLeftMenu extends TopLeftMenu {
  static replaces = 'TopLeftMenu';

  constructor() {
    super();

    console.info('GgTopLeftMenu', this.signOut);
  }

  signOut() {
    dis.dispatch({action: 'logout'});

    this.closeMenu();
  }

}