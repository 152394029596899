'use strict';

import SdkConfig from 'matrix-react-sdk/lib/SdkConfig';
import MatrixClientPeg from 'matrix-react-sdk/lib/MatrixClientPeg';
import ratelimitedfunc from 'matrix-react-sdk/lib/ratelimitedfunc';


export default class ApiSession {

  static get instance(){
    return ApiSession._instance = (ApiSession._instance || new ApiSession());
  }

  constructor(){
    this.storage = window.localStorage;

    this.checkToken = ratelimitedfunc(this.checkToken, 2000);
  }

  init(){
    console.log('ApiSession initialized');
    const token = this.token || this.storage.getItem('gg_api_token');
    if ( token ) {
      this.setToken(token);

    } else {
      const cli = MatrixClientPeg.get();
      cli && cli.logout();
    }
  }


  clearToken(){
    this.storage.removeItem('gg_api_token');
    this.cancelPending();
    this.token = this._user = null;
  }
  setToken(token){
    const atob = window.atob;
    try {
      let parts = token.split('.');
      this.payload = JSON.parse(atob(parts[1]));
    } catch(e) {
      this.payload = null;
    }

    if ( this._checkToken() ) {
      this.token = token;
      this.storage.setItem('gg_api_token', token);

      // renew 1min before expire
      const delta = (this.payload.exp -60)*1000 - Date.now();
      this.cancelPending();
      this._toid = setTimeout(()=>this._renew(), delta);

      console.log('api token expires '+ new Date(this.getExpiry()));
    }
  }

  getToken(){
    return this.token;
  }
  hasToken(){
    return !! this.token;
  }

  checkToken(){
    this._checkToken();
  }
  _checkToken(){
    if ( this.isValid() && this.isExpired() ) {
      console.log('jwt token expired');

      const cli = MatrixClientPeg.get();
      cli && cli.logout();

      this.clearToken();
      return false;
    }
    return true;
  }


  isValid(){
    return !! this.payload;
  }

  isExpired(){
    return this.isValid() && Date.now() > this.payload.exp *1000;
  }

  getExpiry(){
    return this.isValid() && this.payload.exp *1000 || null;
  }

  getUserID(){
    return this.isValid() && this.payload._id || null;
  }


  cancelPending(){
    if ( this._toid ) {
      clearTimeout(this._toid);
      this._toid = null;
    }
  }

  _renew(){
    if ( this._toid ) this._toid = null;

    const api_url = SdkConfig.get()['gg_api_url'];
    
    fetch(api_url +'/authentication/refreshToken', {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.token,
      },
    }).then(res=>{
      if ( res.ok ) {
        this.intercept(res);
      } else {
        throw res;
      }
    }).catch(err=>{
      // force logout
    });
  }


  intercept(res){
    const token = res.headers.get('Authorization');
    this.setToken(token);
    return res;
  }

}