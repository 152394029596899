'use strict';

import HeaderButton from 'matrix-react-sdk/lib/components/views/right_panel/HeaderButton';
import MessageComposer from 'matrix-react-sdk/lib/components/views/rooms/MessageComposer';
import RightPanel from 'matrix-react-sdk/lib/components/structures/RightPanel';
import GgRightPanel from '../../structures/GgRightPanel';
import ApiSession from '../../../ApiSession';

import SdkConfig from 'matrix-react-sdk/lib/SdkConfig';
import { _t, _td } from 'matrix-react-sdk/lib/languageHandler';
import MatrixClientPeg from 'matrix-react-sdk/lib/MatrixClientPeg';
import sdk from 'matrix-react-sdk/lib/index';
import dis from 'matrix-react-sdk/lib/dispatcher';
import Stickerpicker from 'matrix-react-sdk/lib/components/views/rooms/Stickerpicker';
import { makeRoomPermalink } from 'matrix-react-sdk/lib/matrix-to';
import classNames from 'classnames';


require('./GgMessageComposer.scss');


export default class GgMessageComposer extends MessageComposer {
  static replaces = 'MessageComposer';

  constructor(props) {
    super(props);
    
    this.state.user = ApiSession.instance.user;
    this.state.phase = null;
    this.onAction = this.onAction.bind(this);
  }

  componentWillMount() {
    this.dispatcherRef = dis.register(this.onAction);
  }

  componentDidMount(){
    super.componentDidMount();

    if ( ! ApiSession.instance.user ) {
        const cli = MatrixClientPeg.get();
        const userid = cli.getUserId().replace(/^@gg(\d+).*$/, '$1');
        const api_url = SdkConfig.get()['gg_api_url'];

        fetch(`${api_url}/users/${userid}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': ApiSession.instance.getToken(),
        },
        })
        .then(res=>res.ok && res.json())
        .then(user=>{
            ApiSession.instance.user = user;
            this.setState({user});
        })
        .catch(err=>{
            console.error('Unable to retrieve user data, logging out');
            const cli = MatrixClientPeg.get();
            cli && cli.logout();
        });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    dis.unregister(this.dispatcherRef);
  }

  setPhase(phase, extras) {
    // TODO: delay?
    dis.dispatch(Object.assign({
      action: 'view_right_panel_phase',
      phase: phase,
    }, extras));
  }

  isPhase(phases) {
    if (Array.isArray(phases)) {
      return phases.includes(this.state.phase);
    } else {
      return phases === this.state.phase;
    }
  }


  onAction(payload) {
    // listen for actions from right panel
    if ( payload.action === "view_right_panel_phase" ) {
      if (this.state.phase === payload.phase && payload.fromHeader) {
        this.setState({
          phase: null,
        });
      } else {
        this.setState({
          phase: payload.phase,
        });
      }
    }
  }



  render() {
    const uploadInputStyle = {display: 'none'};
    const MemberStatusMessageAvatar = sdk.getComponent('avatars.MemberStatusMessageAvatar');
    const MessageComposerInput = sdk.getComponent("rooms.MessageComposerInput");

    const controls = [];

    if (this.state.me) {
        controls.push(
            <div key="controls_avatar" className="mx_MessageComposer_avatar">
                <MemberStatusMessageAvatar member={this.state.me} width={24} height={24} />
            </div>,
        );
    }

    if (this.props.e2eStatus) {
        controls.push(<E2EIcon
            status={this.props.e2eStatus}
            key="e2eIcon"
            className="mx_MessageComposer_e2eIcon" />
        );
    }

    let callButton;
    let videoCallButton;
    let hangupButton;

    const AccessibleButton = sdk.getComponent('elements.AccessibleButton');
    // Call buttons
    if (this.props.callState && this.props.callState !== 'ended') {
        hangupButton =
            <AccessibleButton className="mx_MessageComposer_button mx_MessageComposer_hangup"
                key="controls_hangup"
                onClick={this.onHangupClick}
                title={_t('Hangup')}
            >
            </AccessibleButton>;
    } else {
        callButton =
            <AccessibleButton className="mx_MessageComposer_button mx_MessageComposer_voicecall"
                key="controls_call"
                onClick={this.onVoiceCallClick}
                title={_t('Voice call')}
            >
            </AccessibleButton>;
        videoCallButton =
            <AccessibleButton className="mx_MessageComposer_button mx_MessageComposer_videocall"
                key="controls_videocall"
                onClick={this.onCallClick}
                title={_t('Video call')}
            >
            </AccessibleButton>;
    }

    const canSendMessages = !this.state.tombstone &&
        this.props.room.maySendMessage();

    // TODO: Remove temporary logging for riot-web#7838
    // Note: we rip apart the power level event ourselves because we don't want to
    // log too much data about it - just the bits we care about. Many of the variables
    // logged here are to help figure out where in the stack the 'cannot post in room'
    // warning is coming from. This means logging various numbers from the PL event to
    // verify RoomState._maySendEventOfType is doing the right thing.
    const room = this.props.room;
    const plEvent = room.currentState.getStateEvents('m.room.power_levels', '');
    let plEventString = "<no power level event>";
    if (plEvent) {
        const content = plEvent.getContent();
        if (!content) {
            plEventString = "<no event content>";
        } else {
            const stringifyFalsey = (v) => v === null ? '<null>' : (v === undefined ? '<undefined>' : v);
            const actualUserPl = stringifyFalsey(content.users ? content.users[room.myUserId] : "<no users in content>");
            const usersPl = stringifyFalsey(content.users_default);
            const actualEventPl = stringifyFalsey(content.events ? content.events['m.room.message'] : "<no events in content>");
            const eventPl = stringifyFalsey(content.events_default);
            plEventString = `actualUserPl=${actualUserPl} defaultUserPl=${usersPl} actualEventPl=${actualEventPl} defaultEventPl=${eventPl}`;
        }
    }
    console.log(
        `[riot-web#7838] renderComposer() hasTombstone=${!!this.state.tombstone} maySendMessage=${room.maySendMessage()}` +
        ` myMembership=${room.getMyMembership()} maySendEvent=${room.currentState.maySendEvent('m.room.message', room.myUserId)}` +
        ` myUserId=${room.myUserId} roomId=${room.roomId} hasPlEvent=${!!plEvent} powerLevels='${plEventString}'`
    );

    if (canSendMessages) {
        // This also currently includes the call buttons. Really we should
        // check separately for whether we can call, but this is slightly
        // complex because of conference calls.
        const uploadButton = (
            <AccessibleButton className="mx_MessageComposer_button mx_MessageComposer_upload"
                key="controls_upload"
                onClick={this.onUploadClick}
                title={_t('Upload file')}
            >
                <input ref="uploadInput" type="file"
                    style={uploadInputStyle}
                    multiple
                    onChange={this.onUploadFileSelected} />
            </AccessibleButton>
        );

        const formattingButton = this.state.inputState.isRichTextEnabled ? (
            <AccessibleButton element="img" className="mx_MessageComposer_formatting"
                 alt={_t("Show Text Formatting Toolbar")}
                 title={_t("Show Text Formatting Toolbar")}
                 src={require("matrix-react-sdk/res/img/button-text-formatting.svg")}
                 onClick={this.onToggleFormattingClicked}
                 style={{visibility: this.state.showFormatting ? 'hidden' : 'visible'}}
                 key="controls_formatting" />
        ) : null;

        // const tasksButton = <AccessibleButton className="mx_MessageComposer_button mx_MessageComposer_tasks"
        //        title={_t("Show Student's Tasks")}
        //        onClick={this.onToggleFormattingClicked}
        //        key="controls_tasks" />;
        const user = this.state.user;
        const tasksButton = user && user.roles && (user.roles.coach || user.roles.coaching_corps_coach) ? (
            <HeaderButton key="tasksButton" name="tasksButton"
                title={_t('Student\'s Tasks')}
                isHighlighted={this.isPhase(GgRightPanel.Phase.TasksList)}
                clickPhase={GgRightPanel.Phase.TasksList}
                analytics={['Right Panel', 'Tasks List Button', 'click']}
            />
        ) : null;

        const roomIsEncrypted = MatrixClientPeg.get().isRoomEncrypted(this.props.room.roomId);
        let placeholderText;
        if (this.state.isQuoting) {
            if (roomIsEncrypted) {
                placeholderText = _t('Send an encrypted reply…');
            } else {
                placeholderText = _t('Send a reply (unencrypted)…');
            }
        } else {
            if (roomIsEncrypted) {
                placeholderText = _t('Send an encrypted message…');
            } else {
                placeholderText = _t('Send a message (unencrypted)…');
            }
        }

        const stickerpickerButton = <Stickerpicker key='stickerpicker_controls_button' room={this.props.room} />;

        controls.push(
            <MessageComposerInput
                ref={(c) => this.messageComposerInput = c}
                key="controls_input"
                onResize={this.props.onResize}
                room={this.props.room}
                placeholder={placeholderText}
                onFilesPasted={this.uploadFiles}
                onInputStateChanged={this.onInputStateChanged} />,
            formattingButton,
            tasksButton,
            // stickerpickerButton,
            uploadButton,
            // hangupButton,
            // callButton,
            // videoCallButton,
        );
    } else if (this.state.tombstone) {
        const replacementRoomId = this.state.tombstone.getContent()['replacement_room'];

        controls.push(<div className="mx_MessageComposer_replaced_wrapper">
            <div className="mx_MessageComposer_replaced_valign">
                <img className="mx_MessageComposer_roomReplaced_icon" src={require("matrix-react-sdk/res/img/room_replaced.svg")} />
                <span className="mx_MessageComposer_roomReplaced_header">
                    {_t("This room has been replaced and is no longer active.")}
                </span><br />
                <a href={makeRoomPermalink(replacementRoomId)}
                    className="mx_MessageComposer_roomReplaced_link"
                    onClick={this._onTombstoneClick}
                >
                    {_t("The conversation continues here.")}
                </a>
            </div>
        </div>);
    } else {
        // TODO: Remove temporary logging for riot-web#7838
        console.log("[riot-web#7838] Falling back to showing cannot post in room error");
        controls.push(
            <div key="controls_error" className="mx_MessageComposer_noperm_error">
                { _t('You do not have permission to post to this room') }
            </div>,
        );
    }

    let formatBar;
    if (this.state.showFormatting && this.state.inputState.isRichTextEnabled) {
        const {marks, blockType} = this.state.inputState;
        const formatButtons = formatButtonList.map((name) => {
            // special-case to match the md serializer and the special-case in MessageComposerInput.js
            const markName = name === 'inline-code' ? 'code' : name;
            const active = marks.some(mark => mark.type === markName) || blockType === name;
            const suffix = active ? '-on' : '';
            const onFormatButtonClicked = this.onFormatButtonClicked.bind(this, name);
            const className = 'mx_MessageComposer_format_button mx_filterFlipColor';
            return <img className={className}
                        title={_t(name)}
                        onMouseDown={onFormatButtonClicked}
                        key={name}
                        src={require(`matrix-react-sdk/res/img/button-text-${name}${suffix}.svg`)}
                        height="17" />;
            },
        );

        formatBar =
            <div className="mx_MessageComposer_formatbar_wrapper">
                <div className="mx_MessageComposer_formatbar">
                    { formatButtons }
                    <div style={{flex: 1}}></div>
                    <img title={this.state.inputState.isRichTextEnabled ? _t("Turn Markdown on") : _t("Turn Markdown off")}
                         onMouseDown={this.onToggleMarkdownClicked}
                        className="mx_MessageComposer_formatbar_markdown mx_filterFlipColor"
                        src={require(`matrix-react-sdk/res/img/button-md-${!this.state.inputState.isRichTextEnabled}.png`)} />
                    <AccessibleButton element="img" title={_t("Hide Text Formatting Toolbar")}
                         onClick={this.onToggleFormattingClicked}
                         className="mx_MessageComposer_formatbar_cancel mx_filterFlipColor"
                         src={require("matrix-react-sdk/res/img/icon-text-cancel.svg")} />
                </div>
            </div>;
    }

    const wrapperClasses = classNames({
        mx_MessageComposer_wrapper: true,
        mx_MessageComposer_hasE2EIcon: !!this.props.e2eStatus,
    });
    return (
        <div className="mx_MessageComposer">
            <div className={wrapperClasses}>
                <div className="mx_MessageComposer_row">
                    { controls }
                </div>
            </div>
            { formatBar }
        </div>
    );
  }

}
