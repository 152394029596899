'use strict';

import classNames from 'classnames';
import RightPanel from 'matrix-react-sdk/lib/components/structures/RightPanel';
import sdk from 'matrix-react-sdk/lib/index';
import GgTasksList from '../views/right_panel/GgTasksList';


export default class GgRightPanel extends RightPanel {
  // static replaces = 'RightPanel';

  static Phase = Object.freeze({
    TasksList: 'TasksList',
  });


  constructor(props, context) {
  	super(props, context);
  }

  render(){
    const GgTasksList = sdk.getComponent('rooms.GgTasksList');

    if ( this.state.phase === GgRightPanel.Phase.TasksList ) {
      const classes = classNames("mx_RightPanel", "mx_fadable", {
          "collapsed": this.props.collapsed,
          "mx_fadable_faded": this.props.disabled,
          "dark-panel": true,
      });

      console.log('tasks list', this.props, this.state);

      return (
          <aside className={classes}>
            <GgTasksList roomId={this.props.roomId} key={this.props.roomId} />
          </aside>
      );
    }
    return super.render();
  }

}

const ogRender = RightPanel.prototype.render;
RightPanel.prototype.render = function(){
  // const GgTasksList = sdk.getComponent('GgTasksList');

  if ( this.state.phase === GgRightPanel.Phase.TasksList ) {
    const classes = classNames("mx_RightPanel", "mx_fadable", {
        "collapsed": this.props.collapsed,
        "mx_fadable_faded": this.props.disabled,
        "dark-panel": true,
    });

    console.log('tasks list', this.props, this.state);

    return (
        <aside className={classes}>
          <GgTasksList roomId={this.props.roomId} key={this.props.roomId} />
        </aside>
    );
  }
  return ogRender.call(this);
}