'use strict';

import { _t } from 'matrix-react-sdk/lib/languageHandler';
import dis from 'matrix-react-sdk/lib/dispatcher';
import HeaderButton from 'matrix-react-sdk/lib/components/views/right_panel/HeaderButton';
import RightPanel from 'matrix-react-sdk/lib/components/structures/RightPanel';
import RoomHeaderButtons from 'matrix-react-sdk/lib/components/views/right_panel/RoomHeaderButtons';
import GgRightPanel from '../../structures/GgRightPanel';


export default class GgRoomHeaderButtons extends RoomHeaderButtons {
  static replaces = 'RoomHeaderButtons';

  constructor(props) {
  	super(props);

  }

  renderButtons() {
    const membersPhases = [
        RightPanel.Phase.RoomMemberList,
        RightPanel.Phase.RoomMemberInfo,
    ];

    return [
        <HeaderButton key="membersButton" name="membersButton"
            title={_t('Members')}
            isHighlighted={this.isPhase(membersPhases)}
            clickPhase={RightPanel.Phase.RoomMemberList}
            analytics={['Right Panel', 'Member List Button', 'click']}
        />,
        <HeaderButton key="filesButton" name="filesButton"
            title={_t('Files')}
            isHighlighted={this.isPhase(RightPanel.Phase.FilePanel)}
            clickPhase={RightPanel.Phase.FilePanel}
            analytics={['Right Panel', 'File List Button', 'click']}
        />,
        <HeaderButton key="notifsButton" name="notifsButton"
            title={_t('Notifications')}
            isHighlighted={this.isPhase(RightPanel.Phase.NotificationPanel)}
            clickPhase={RightPanel.Phase.NotificationPanel}
            analytics={['Right Panel', 'Notification List Button', 'click']}
        />,
    ];
  }
}
