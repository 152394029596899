/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2017 Vector Creations Ltd
Copyright 2017, 2018 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

/*
 * THIS FILE IS AUTO-GENERATED
 * You can edit it you like, but your changes will be overwritten,
 * so you'd just be trying to swim upstream like a salmon.
 * You are not a salmon.
 */

let components = {};
import structures$AutoHideScrollbar from './components/structures/AutoHideScrollbar';
structures$AutoHideScrollbar && (components['structures.AutoHideScrollbar'] = structures$AutoHideScrollbar);
import structures$BottomLeftMenu from './components/structures/BottomLeftMenu';
structures$BottomLeftMenu && (components['structures.BottomLeftMenu'] = structures$BottomLeftMenu);
import structures$CompatibilityPage from './components/structures/CompatibilityPage';
structures$CompatibilityPage && (components['structures.CompatibilityPage'] = structures$CompatibilityPage);
import structures$ContextualMenu from './components/structures/ContextualMenu';
structures$ContextualMenu && (components['structures.ContextualMenu'] = structures$ContextualMenu);
import structures$CustomRoomTagPanel from './components/structures/CustomRoomTagPanel';
structures$CustomRoomTagPanel && (components['structures.CustomRoomTagPanel'] = structures$CustomRoomTagPanel);
import structures$EmbeddedPage from './components/structures/EmbeddedPage';
structures$EmbeddedPage && (components['structures.EmbeddedPage'] = structures$EmbeddedPage);
import structures$FilePanel from './components/structures/FilePanel';
structures$FilePanel && (components['structures.FilePanel'] = structures$FilePanel);
import structures$GroupView from './components/structures/GroupView';
structures$GroupView && (components['structures.GroupView'] = structures$GroupView);
import structures$IndicatorScrollbar from './components/structures/IndicatorScrollbar';
structures$IndicatorScrollbar && (components['structures.IndicatorScrollbar'] = structures$IndicatorScrollbar);
import structures$InteractiveAuth from './components/structures/InteractiveAuth';
structures$InteractiveAuth && (components['structures.InteractiveAuth'] = structures$InteractiveAuth);
import structures$LeftPanel from './components/structures/LeftPanel';
structures$LeftPanel && (components['structures.LeftPanel'] = structures$LeftPanel);
import structures$LoggedInView from './components/structures/LoggedInView';
structures$LoggedInView && (components['structures.LoggedInView'] = structures$LoggedInView);
import structures$MainSplit from './components/structures/MainSplit';
structures$MainSplit && (components['structures.MainSplit'] = structures$MainSplit);
import structures$MatrixChat from './components/structures/MatrixChat';
structures$MatrixChat && (components['structures.MatrixChat'] = structures$MatrixChat);
import structures$MessagePanel from './components/structures/MessagePanel';
structures$MessagePanel && (components['structures.MessagePanel'] = structures$MessagePanel);
import structures$MyGroups from './components/structures/MyGroups';
structures$MyGroups && (components['structures.MyGroups'] = structures$MyGroups);
import structures$NotificationPanel from './components/structures/NotificationPanel';
structures$NotificationPanel && (components['structures.NotificationPanel'] = structures$NotificationPanel);
import structures$RightPanel from './components/structures/RightPanel';
structures$RightPanel && (components['structures.RightPanel'] = structures$RightPanel);
import structures$RoomDirectory from './components/structures/RoomDirectory';
structures$RoomDirectory && (components['structures.RoomDirectory'] = structures$RoomDirectory);
import structures$RoomStatusBar from './components/structures/RoomStatusBar';
structures$RoomStatusBar && (components['structures.RoomStatusBar'] = structures$RoomStatusBar);
import structures$RoomSubList from './components/structures/RoomSubList';
structures$RoomSubList && (components['structures.RoomSubList'] = structures$RoomSubList);
import structures$RoomView from './components/structures/RoomView';
structures$RoomView && (components['structures.RoomView'] = structures$RoomView);
import structures$ScrollPanel from './components/structures/ScrollPanel';
structures$ScrollPanel && (components['structures.ScrollPanel'] = structures$ScrollPanel);
import structures$SearchBox from './components/structures/SearchBox';
structures$SearchBox && (components['structures.SearchBox'] = structures$SearchBox);
import structures$TabbedView from './components/structures/TabbedView';
structures$TabbedView && (components['structures.TabbedView'] = structures$TabbedView);
import structures$TagPanel from './components/structures/TagPanel';
structures$TagPanel && (components['structures.TagPanel'] = structures$TagPanel);
import structures$TagPanelButtons from './components/structures/TagPanelButtons';
structures$TagPanelButtons && (components['structures.TagPanelButtons'] = structures$TagPanelButtons);
import structures$TimelinePanel from './components/structures/TimelinePanel';
structures$TimelinePanel && (components['structures.TimelinePanel'] = structures$TimelinePanel);
import structures$TopLeftMenuButton from './components/structures/TopLeftMenuButton';
structures$TopLeftMenuButton && (components['structures.TopLeftMenuButton'] = structures$TopLeftMenuButton);
import structures$UploadBar from './components/structures/UploadBar';
structures$UploadBar && (components['structures.UploadBar'] = structures$UploadBar);
import structures$ViewSource from './components/structures/ViewSource';
structures$ViewSource && (components['structures.ViewSource'] = structures$ViewSource);
import structures$auth$ForgotPassword from './components/structures/auth/ForgotPassword';
structures$auth$ForgotPassword && (components['structures.auth.ForgotPassword'] = structures$auth$ForgotPassword);
import structures$auth$Login from './components/structures/auth/Login';
structures$auth$Login && (components['structures.auth.Login'] = structures$auth$Login);
import structures$auth$PostRegistration from './components/structures/auth/PostRegistration';
structures$auth$PostRegistration && (components['structures.auth.PostRegistration'] = structures$auth$PostRegistration);
import structures$auth$Registration from './components/structures/auth/Registration';
structures$auth$Registration && (components['structures.auth.Registration'] = structures$auth$Registration);
import views$auth$AuthBody from './components/views/auth/AuthBody';
views$auth$AuthBody && (components['views.auth.AuthBody'] = views$auth$AuthBody);
import views$auth$AuthButtons from './components/views/auth/AuthButtons';
views$auth$AuthButtons && (components['views.auth.AuthButtons'] = views$auth$AuthButtons);
import views$auth$AuthFooter from './components/views/auth/AuthFooter';
views$auth$AuthFooter && (components['views.auth.AuthFooter'] = views$auth$AuthFooter);
import views$auth$AuthHeader from './components/views/auth/AuthHeader';
views$auth$AuthHeader && (components['views.auth.AuthHeader'] = views$auth$AuthHeader);
import views$auth$AuthHeaderLogo from './components/views/auth/AuthHeaderLogo';
views$auth$AuthHeaderLogo && (components['views.auth.AuthHeaderLogo'] = views$auth$AuthHeaderLogo);
import views$auth$AuthPage from './components/views/auth/AuthPage';
views$auth$AuthPage && (components['views.auth.AuthPage'] = views$auth$AuthPage);
import views$auth$CaptchaForm from './components/views/auth/CaptchaForm';
views$auth$CaptchaForm && (components['views.auth.CaptchaForm'] = views$auth$CaptchaForm);
import views$auth$CountryDropdown from './components/views/auth/CountryDropdown';
views$auth$CountryDropdown && (components['views.auth.CountryDropdown'] = views$auth$CountryDropdown);
import views$auth$CustomServerDialog from './components/views/auth/CustomServerDialog';
views$auth$CustomServerDialog && (components['views.auth.CustomServerDialog'] = views$auth$CustomServerDialog);
import views$auth$InteractiveAuthEntryComponents from './components/views/auth/InteractiveAuthEntryComponents';
views$auth$InteractiveAuthEntryComponents && (components['views.auth.InteractiveAuthEntryComponents'] = views$auth$InteractiveAuthEntryComponents);
import views$auth$LanguageSelector from './components/views/auth/LanguageSelector';
views$auth$LanguageSelector && (components['views.auth.LanguageSelector'] = views$auth$LanguageSelector);
import views$auth$ModularServerConfig from './components/views/auth/ModularServerConfig';
views$auth$ModularServerConfig && (components['views.auth.ModularServerConfig'] = views$auth$ModularServerConfig);
import views$auth$PasswordLogin from './components/views/auth/PasswordLogin';
views$auth$PasswordLogin && (components['views.auth.PasswordLogin'] = views$auth$PasswordLogin);
import views$auth$RegistrationForm from './components/views/auth/RegistrationForm';
views$auth$RegistrationForm && (components['views.auth.RegistrationForm'] = views$auth$RegistrationForm);
import views$auth$ServerConfig from './components/views/auth/ServerConfig';
views$auth$ServerConfig && (components['views.auth.ServerConfig'] = views$auth$ServerConfig);
import views$auth$ServerTypeSelector from './components/views/auth/ServerTypeSelector';
views$auth$ServerTypeSelector && (components['views.auth.ServerTypeSelector'] = views$auth$ServerTypeSelector);
import views$auth$Welcome from './components/views/auth/Welcome';
views$auth$Welcome && (components['views.auth.Welcome'] = views$auth$Welcome);
import views$avatars$BaseAvatar from './components/views/avatars/BaseAvatar';
views$avatars$BaseAvatar && (components['views.avatars.BaseAvatar'] = views$avatars$BaseAvatar);
import views$avatars$GroupAvatar from './components/views/avatars/GroupAvatar';
views$avatars$GroupAvatar && (components['views.avatars.GroupAvatar'] = views$avatars$GroupAvatar);
import views$avatars$MemberAvatar from './components/views/avatars/MemberAvatar';
views$avatars$MemberAvatar && (components['views.avatars.MemberAvatar'] = views$avatars$MemberAvatar);
import views$avatars$MemberStatusMessageAvatar from './components/views/avatars/MemberStatusMessageAvatar';
views$avatars$MemberStatusMessageAvatar && (components['views.avatars.MemberStatusMessageAvatar'] = views$avatars$MemberStatusMessageAvatar);
import views$avatars$RoomAvatar from './components/views/avatars/RoomAvatar';
views$avatars$RoomAvatar && (components['views.avatars.RoomAvatar'] = views$avatars$RoomAvatar);
import views$context_menus$GenericElementContextMenu from './components/views/context_menus/GenericElementContextMenu';
views$context_menus$GenericElementContextMenu && (components['views.context_menus.GenericElementContextMenu'] = views$context_menus$GenericElementContextMenu);
import views$context_menus$GenericTextContextMenu from './components/views/context_menus/GenericTextContextMenu';
views$context_menus$GenericTextContextMenu && (components['views.context_menus.GenericTextContextMenu'] = views$context_menus$GenericTextContextMenu);
import views$context_menus$GroupInviteTileContextMenu from './components/views/context_menus/GroupInviteTileContextMenu';
views$context_menus$GroupInviteTileContextMenu && (components['views.context_menus.GroupInviteTileContextMenu'] = views$context_menus$GroupInviteTileContextMenu);
import views$context_menus$MessageContextMenu from './components/views/context_menus/MessageContextMenu';
views$context_menus$MessageContextMenu && (components['views.context_menus.MessageContextMenu'] = views$context_menus$MessageContextMenu);
import views$context_menus$RoomTileContextMenu from './components/views/context_menus/RoomTileContextMenu';
views$context_menus$RoomTileContextMenu && (components['views.context_menus.RoomTileContextMenu'] = views$context_menus$RoomTileContextMenu);
import views$context_menus$StatusMessageContextMenu from './components/views/context_menus/StatusMessageContextMenu';
views$context_menus$StatusMessageContextMenu && (components['views.context_menus.StatusMessageContextMenu'] = views$context_menus$StatusMessageContextMenu);
import views$context_menus$TagTileContextMenu from './components/views/context_menus/TagTileContextMenu';
views$context_menus$TagTileContextMenu && (components['views.context_menus.TagTileContextMenu'] = views$context_menus$TagTileContextMenu);
import views$context_menus$TopLeftMenu from './components/views/context_menus/TopLeftMenu';
views$context_menus$TopLeftMenu && (components['views.context_menus.TopLeftMenu'] = views$context_menus$TopLeftMenu);
import views$create_room$CreateRoomButton from './components/views/create_room/CreateRoomButton';
views$create_room$CreateRoomButton && (components['views.create_room.CreateRoomButton'] = views$create_room$CreateRoomButton);
import views$create_room$Presets from './components/views/create_room/Presets';
views$create_room$Presets && (components['views.create_room.Presets'] = views$create_room$Presets);
import views$create_room$RoomAlias from './components/views/create_room/RoomAlias';
views$create_room$RoomAlias && (components['views.create_room.RoomAlias'] = views$create_room$RoomAlias);
import views$dialogs$AddressPickerDialog from './components/views/dialogs/AddressPickerDialog';
views$dialogs$AddressPickerDialog && (components['views.dialogs.AddressPickerDialog'] = views$dialogs$AddressPickerDialog);
import views$dialogs$AskInviteAnywayDialog from './components/views/dialogs/AskInviteAnywayDialog';
views$dialogs$AskInviteAnywayDialog && (components['views.dialogs.AskInviteAnywayDialog'] = views$dialogs$AskInviteAnywayDialog);
import views$dialogs$BaseDialog from './components/views/dialogs/BaseDialog';
views$dialogs$BaseDialog && (components['views.dialogs.BaseDialog'] = views$dialogs$BaseDialog);
import views$dialogs$BugReportDialog from './components/views/dialogs/BugReportDialog';
views$dialogs$BugReportDialog && (components['views.dialogs.BugReportDialog'] = views$dialogs$BugReportDialog);
import views$dialogs$ChangelogDialog from './components/views/dialogs/ChangelogDialog';
views$dialogs$ChangelogDialog && (components['views.dialogs.ChangelogDialog'] = views$dialogs$ChangelogDialog);
import views$dialogs$ChatCreateOrReuseDialog from './components/views/dialogs/ChatCreateOrReuseDialog';
views$dialogs$ChatCreateOrReuseDialog && (components['views.dialogs.ChatCreateOrReuseDialog'] = views$dialogs$ChatCreateOrReuseDialog);
import views$dialogs$ConfirmRedactDialog from './components/views/dialogs/ConfirmRedactDialog';
views$dialogs$ConfirmRedactDialog && (components['views.dialogs.ConfirmRedactDialog'] = views$dialogs$ConfirmRedactDialog);
import views$dialogs$ConfirmUserActionDialog from './components/views/dialogs/ConfirmUserActionDialog';
views$dialogs$ConfirmUserActionDialog && (components['views.dialogs.ConfirmUserActionDialog'] = views$dialogs$ConfirmUserActionDialog);
import views$dialogs$CreateGroupDialog from './components/views/dialogs/CreateGroupDialog';
views$dialogs$CreateGroupDialog && (components['views.dialogs.CreateGroupDialog'] = views$dialogs$CreateGroupDialog);
import views$dialogs$CreateRoomDialog from './components/views/dialogs/CreateRoomDialog';
views$dialogs$CreateRoomDialog && (components['views.dialogs.CreateRoomDialog'] = views$dialogs$CreateRoomDialog);
import views$dialogs$CryptoStoreTooNewDialog from './components/views/dialogs/CryptoStoreTooNewDialog';
views$dialogs$CryptoStoreTooNewDialog && (components['views.dialogs.CryptoStoreTooNewDialog'] = views$dialogs$CryptoStoreTooNewDialog);
import views$dialogs$DeactivateAccountDialog from './components/views/dialogs/DeactivateAccountDialog';
views$dialogs$DeactivateAccountDialog && (components['views.dialogs.DeactivateAccountDialog'] = views$dialogs$DeactivateAccountDialog);
import views$dialogs$DeviceVerifyDialog from './components/views/dialogs/DeviceVerifyDialog';
views$dialogs$DeviceVerifyDialog && (components['views.dialogs.DeviceVerifyDialog'] = views$dialogs$DeviceVerifyDialog);
import views$dialogs$DevtoolsDialog from './components/views/dialogs/DevtoolsDialog';
views$dialogs$DevtoolsDialog && (components['views.dialogs.DevtoolsDialog'] = views$dialogs$DevtoolsDialog);
import views$dialogs$ErrorDialog from './components/views/dialogs/ErrorDialog';
views$dialogs$ErrorDialog && (components['views.dialogs.ErrorDialog'] = views$dialogs$ErrorDialog);
import views$dialogs$IncomingSasDialog from './components/views/dialogs/IncomingSasDialog';
views$dialogs$IncomingSasDialog && (components['views.dialogs.IncomingSasDialog'] = views$dialogs$IncomingSasDialog);
import views$dialogs$InfoDialog from './components/views/dialogs/InfoDialog';
views$dialogs$InfoDialog && (components['views.dialogs.InfoDialog'] = views$dialogs$InfoDialog);
import views$dialogs$InteractiveAuthDialog from './components/views/dialogs/InteractiveAuthDialog';
views$dialogs$InteractiveAuthDialog && (components['views.dialogs.InteractiveAuthDialog'] = views$dialogs$InteractiveAuthDialog);
import views$dialogs$KeyShareDialog from './components/views/dialogs/KeyShareDialog';
views$dialogs$KeyShareDialog && (components['views.dialogs.KeyShareDialog'] = views$dialogs$KeyShareDialog);
import views$dialogs$LazyLoadingDisabledDialog from './components/views/dialogs/LazyLoadingDisabledDialog';
views$dialogs$LazyLoadingDisabledDialog && (components['views.dialogs.LazyLoadingDisabledDialog'] = views$dialogs$LazyLoadingDisabledDialog);
import views$dialogs$LazyLoadingResyncDialog from './components/views/dialogs/LazyLoadingResyncDialog';
views$dialogs$LazyLoadingResyncDialog && (components['views.dialogs.LazyLoadingResyncDialog'] = views$dialogs$LazyLoadingResyncDialog);
import views$dialogs$LogoutDialog from './components/views/dialogs/LogoutDialog';
views$dialogs$LogoutDialog && (components['views.dialogs.LogoutDialog'] = views$dialogs$LogoutDialog);
import views$dialogs$QuestionDialog from './components/views/dialogs/QuestionDialog';
views$dialogs$QuestionDialog && (components['views.dialogs.QuestionDialog'] = views$dialogs$QuestionDialog);
import views$dialogs$RedesignFeedbackDialog from './components/views/dialogs/RedesignFeedbackDialog';
views$dialogs$RedesignFeedbackDialog && (components['views.dialogs.RedesignFeedbackDialog'] = views$dialogs$RedesignFeedbackDialog);
import views$dialogs$RoomSettingsDialog from './components/views/dialogs/RoomSettingsDialog';
views$dialogs$RoomSettingsDialog && (components['views.dialogs.RoomSettingsDialog'] = views$dialogs$RoomSettingsDialog);
import views$dialogs$RoomUpgradeDialog from './components/views/dialogs/RoomUpgradeDialog';
views$dialogs$RoomUpgradeDialog && (components['views.dialogs.RoomUpgradeDialog'] = views$dialogs$RoomUpgradeDialog);
import views$dialogs$SessionRestoreErrorDialog from './components/views/dialogs/SessionRestoreErrorDialog';
views$dialogs$SessionRestoreErrorDialog && (components['views.dialogs.SessionRestoreErrorDialog'] = views$dialogs$SessionRestoreErrorDialog);
import views$dialogs$SetEmailDialog from './components/views/dialogs/SetEmailDialog';
views$dialogs$SetEmailDialog && (components['views.dialogs.SetEmailDialog'] = views$dialogs$SetEmailDialog);
import views$dialogs$SetMxIdDialog from './components/views/dialogs/SetMxIdDialog';
views$dialogs$SetMxIdDialog && (components['views.dialogs.SetMxIdDialog'] = views$dialogs$SetMxIdDialog);
import views$dialogs$SetPasswordDialog from './components/views/dialogs/SetPasswordDialog';
views$dialogs$SetPasswordDialog && (components['views.dialogs.SetPasswordDialog'] = views$dialogs$SetPasswordDialog);
import views$dialogs$ShareDialog from './components/views/dialogs/ShareDialog';
views$dialogs$ShareDialog && (components['views.dialogs.ShareDialog'] = views$dialogs$ShareDialog);
import views$dialogs$TextInputDialog from './components/views/dialogs/TextInputDialog';
views$dialogs$TextInputDialog && (components['views.dialogs.TextInputDialog'] = views$dialogs$TextInputDialog);
import views$dialogs$UnknownDeviceDialog from './components/views/dialogs/UnknownDeviceDialog';
views$dialogs$UnknownDeviceDialog && (components['views.dialogs.UnknownDeviceDialog'] = views$dialogs$UnknownDeviceDialog);
import views$dialogs$UserSettingsDialog from './components/views/dialogs/UserSettingsDialog';
views$dialogs$UserSettingsDialog && (components['views.dialogs.UserSettingsDialog'] = views$dialogs$UserSettingsDialog);
import views$dialogs$keybackup$RestoreKeyBackupDialog from './components/views/dialogs/keybackup/RestoreKeyBackupDialog';
views$dialogs$keybackup$RestoreKeyBackupDialog && (components['views.dialogs.keybackup.RestoreKeyBackupDialog'] = views$dialogs$keybackup$RestoreKeyBackupDialog);
import views$directory$NetworkDropdown from './components/views/directory/NetworkDropdown';
views$directory$NetworkDropdown && (components['views.directory.NetworkDropdown'] = views$directory$NetworkDropdown);
import views$elements$AccessibleButton from './components/views/elements/AccessibleButton';
views$elements$AccessibleButton && (components['views.elements.AccessibleButton'] = views$elements$AccessibleButton);
import views$elements$ActionButton from './components/views/elements/ActionButton';
views$elements$ActionButton && (components['views.elements.ActionButton'] = views$elements$ActionButton);
import views$elements$AddressSelector from './components/views/elements/AddressSelector';
views$elements$AddressSelector && (components['views.elements.AddressSelector'] = views$elements$AddressSelector);
import views$elements$AddressTile from './components/views/elements/AddressTile';
views$elements$AddressTile && (components['views.elements.AddressTile'] = views$elements$AddressTile);
import views$elements$AppPermission from './components/views/elements/AppPermission';
views$elements$AppPermission && (components['views.elements.AppPermission'] = views$elements$AppPermission);
import views$elements$AppTile from './components/views/elements/AppTile';
views$elements$AppTile && (components['views.elements.AppTile'] = views$elements$AppTile);
import views$elements$AppWarning from './components/views/elements/AppWarning';
views$elements$AppWarning && (components['views.elements.AppWarning'] = views$elements$AppWarning);
import views$elements$CreateRoomButton from './components/views/elements/CreateRoomButton';
views$elements$CreateRoomButton && (components['views.elements.CreateRoomButton'] = views$elements$CreateRoomButton);
import views$elements$DNDTagTile from './components/views/elements/DNDTagTile';
views$elements$DNDTagTile && (components['views.elements.DNDTagTile'] = views$elements$DNDTagTile);
import views$elements$DeviceVerifyButtons from './components/views/elements/DeviceVerifyButtons';
views$elements$DeviceVerifyButtons && (components['views.elements.DeviceVerifyButtons'] = views$elements$DeviceVerifyButtons);
import views$elements$DialogButtons from './components/views/elements/DialogButtons';
views$elements$DialogButtons && (components['views.elements.DialogButtons'] = views$elements$DialogButtons);
import views$elements$DirectorySearchBox from './components/views/elements/DirectorySearchBox';
views$elements$DirectorySearchBox && (components['views.elements.DirectorySearchBox'] = views$elements$DirectorySearchBox);
import views$elements$Dropdown from './components/views/elements/Dropdown';
views$elements$Dropdown && (components['views.elements.Dropdown'] = views$elements$Dropdown);
import views$elements$EditableItemList from './components/views/elements/EditableItemList';
views$elements$EditableItemList && (components['views.elements.EditableItemList'] = views$elements$EditableItemList);
import views$elements$EditableText from './components/views/elements/EditableText';
views$elements$EditableText && (components['views.elements.EditableText'] = views$elements$EditableText);
import views$elements$EditableTextContainer from './components/views/elements/EditableTextContainer';
views$elements$EditableTextContainer && (components['views.elements.EditableTextContainer'] = views$elements$EditableTextContainer);
import views$elements$EmojiText from './components/views/elements/EmojiText';
views$elements$EmojiText && (components['views.elements.EmojiText'] = views$elements$EmojiText);
import views$elements$Field from './components/views/elements/Field';
views$elements$Field && (components['views.elements.Field'] = views$elements$Field);
import views$elements$Flair from './components/views/elements/Flair';
views$elements$Flair && (components['views.elements.Flair'] = views$elements$Flair);
import views$elements$GeminiScrollbarWrapper from './components/views/elements/GeminiScrollbarWrapper';
views$elements$GeminiScrollbarWrapper && (components['views.elements.GeminiScrollbarWrapper'] = views$elements$GeminiScrollbarWrapper);
import views$elements$GroupsButton from './components/views/elements/GroupsButton';
views$elements$GroupsButton && (components['views.elements.GroupsButton'] = views$elements$GroupsButton);
import views$elements$HomeButton from './components/views/elements/HomeButton';
views$elements$HomeButton && (components['views.elements.HomeButton'] = views$elements$HomeButton);
import views$elements$ImageView from './components/views/elements/ImageView';
views$elements$ImageView && (components['views.elements.ImageView'] = views$elements$ImageView);
import views$elements$InlineSpinner from './components/views/elements/InlineSpinner';
views$elements$InlineSpinner && (components['views.elements.InlineSpinner'] = views$elements$InlineSpinner);
import views$elements$LabelledToggleSwitch from './components/views/elements/LabelledToggleSwitch';
views$elements$LabelledToggleSwitch && (components['views.elements.LabelledToggleSwitch'] = views$elements$LabelledToggleSwitch);
import views$elements$LanguageDropdown from './components/views/elements/LanguageDropdown';
views$elements$LanguageDropdown && (components['views.elements.LanguageDropdown'] = views$elements$LanguageDropdown);
import views$elements$LazyRenderList from './components/views/elements/LazyRenderList';
views$elements$LazyRenderList && (components['views.elements.LazyRenderList'] = views$elements$LazyRenderList);
import views$elements$ManageIntegsButton from './components/views/elements/ManageIntegsButton';
views$elements$ManageIntegsButton && (components['views.elements.ManageIntegsButton'] = views$elements$ManageIntegsButton);
import views$elements$MemberEventListSummary from './components/views/elements/MemberEventListSummary';
views$elements$MemberEventListSummary && (components['views.elements.MemberEventListSummary'] = views$elements$MemberEventListSummary);
import views$elements$MessageSpinner from './components/views/elements/MessageSpinner';
views$elements$MessageSpinner && (components['views.elements.MessageSpinner'] = views$elements$MessageSpinner);
import views$elements$PersistedElement from './components/views/elements/PersistedElement';
views$elements$PersistedElement && (components['views.elements.PersistedElement'] = views$elements$PersistedElement);
import views$elements$PersistentApp from './components/views/elements/PersistentApp';
views$elements$PersistentApp && (components['views.elements.PersistentApp'] = views$elements$PersistentApp);
import views$elements$Pill from './components/views/elements/Pill';
views$elements$Pill && (components['views.elements.Pill'] = views$elements$Pill);
import views$elements$PowerSelector from './components/views/elements/PowerSelector';
views$elements$PowerSelector && (components['views.elements.PowerSelector'] = views$elements$PowerSelector);
import views$elements$ProgressBar from './components/views/elements/ProgressBar';
views$elements$ProgressBar && (components['views.elements.ProgressBar'] = views$elements$ProgressBar);
import views$elements$ReplyThread from './components/views/elements/ReplyThread';
views$elements$ReplyThread && (components['views.elements.ReplyThread'] = views$elements$ReplyThread);
import views$elements$ResizeHandle from './components/views/elements/ResizeHandle';
views$elements$ResizeHandle && (components['views.elements.ResizeHandle'] = views$elements$ResizeHandle);
import views$elements$RoomDirectoryButton from './components/views/elements/RoomDirectoryButton';
views$elements$RoomDirectoryButton && (components['views.elements.RoomDirectoryButton'] = views$elements$RoomDirectoryButton);
import views$elements$SettingsButton from './components/views/elements/SettingsButton';
views$elements$SettingsButton && (components['views.elements.SettingsButton'] = views$elements$SettingsButton);
import views$elements$SettingsFlag from './components/views/elements/SettingsFlag';
views$elements$SettingsFlag && (components['views.elements.SettingsFlag'] = views$elements$SettingsFlag);
import views$elements$Spinner from './components/views/elements/Spinner';
views$elements$Spinner && (components['views.elements.Spinner'] = views$elements$Spinner);
import views$elements$StartChatButton from './components/views/elements/StartChatButton';
views$elements$StartChatButton && (components['views.elements.StartChatButton'] = views$elements$StartChatButton);
import views$elements$SyntaxHighlight from './components/views/elements/SyntaxHighlight';
views$elements$SyntaxHighlight && (components['views.elements.SyntaxHighlight'] = views$elements$SyntaxHighlight);
import views$elements$TagTile from './components/views/elements/TagTile';
views$elements$TagTile && (components['views.elements.TagTile'] = views$elements$TagTile);
import views$elements$TintableSvg from './components/views/elements/TintableSvg';
views$elements$TintableSvg && (components['views.elements.TintableSvg'] = views$elements$TintableSvg);
import views$elements$TintableSvgButton from './components/views/elements/TintableSvgButton';
views$elements$TintableSvgButton && (components['views.elements.TintableSvgButton'] = views$elements$TintableSvgButton);
import views$elements$ToggleSwitch from './components/views/elements/ToggleSwitch';
views$elements$ToggleSwitch && (components['views.elements.ToggleSwitch'] = views$elements$ToggleSwitch);
import views$elements$ToolTipButton from './components/views/elements/ToolTipButton';
views$elements$ToolTipButton && (components['views.elements.ToolTipButton'] = views$elements$ToolTipButton);
import views$elements$TruncatedList from './components/views/elements/TruncatedList';
views$elements$TruncatedList && (components['views.elements.TruncatedList'] = views$elements$TruncatedList);
import views$elements$UserSelector from './components/views/elements/UserSelector';
views$elements$UserSelector && (components['views.elements.UserSelector'] = views$elements$UserSelector);
import views$globals$CookieBar from './components/views/globals/CookieBar';
views$globals$CookieBar && (components['views.globals.CookieBar'] = views$globals$CookieBar);
import views$globals$MatrixToolbar from './components/views/globals/MatrixToolbar';
views$globals$MatrixToolbar && (components['views.globals.MatrixToolbar'] = views$globals$MatrixToolbar);
import views$globals$NewVersionBar from './components/views/globals/NewVersionBar';
views$globals$NewVersionBar && (components['views.globals.NewVersionBar'] = views$globals$NewVersionBar);
import views$globals$PasswordNagBar from './components/views/globals/PasswordNagBar';
views$globals$PasswordNagBar && (components['views.globals.PasswordNagBar'] = views$globals$PasswordNagBar);
import views$globals$ServerLimitBar from './components/views/globals/ServerLimitBar';
views$globals$ServerLimitBar && (components['views.globals.ServerLimitBar'] = views$globals$ServerLimitBar);
import views$globals$UpdateCheckBar from './components/views/globals/UpdateCheckBar';
views$globals$UpdateCheckBar && (components['views.globals.UpdateCheckBar'] = views$globals$UpdateCheckBar);
import views$groups$GroupInviteTile from './components/views/groups/GroupInviteTile';
views$groups$GroupInviteTile && (components['views.groups.GroupInviteTile'] = views$groups$GroupInviteTile);
import views$groups$GroupMemberInfo from './components/views/groups/GroupMemberInfo';
views$groups$GroupMemberInfo && (components['views.groups.GroupMemberInfo'] = views$groups$GroupMemberInfo);
import views$groups$GroupMemberList from './components/views/groups/GroupMemberList';
views$groups$GroupMemberList && (components['views.groups.GroupMemberList'] = views$groups$GroupMemberList);
import views$groups$GroupMemberTile from './components/views/groups/GroupMemberTile';
views$groups$GroupMemberTile && (components['views.groups.GroupMemberTile'] = views$groups$GroupMemberTile);
import views$groups$GroupPublicityToggle from './components/views/groups/GroupPublicityToggle';
views$groups$GroupPublicityToggle && (components['views.groups.GroupPublicityToggle'] = views$groups$GroupPublicityToggle);
import views$groups$GroupRoomInfo from './components/views/groups/GroupRoomInfo';
views$groups$GroupRoomInfo && (components['views.groups.GroupRoomInfo'] = views$groups$GroupRoomInfo);
import views$groups$GroupRoomList from './components/views/groups/GroupRoomList';
views$groups$GroupRoomList && (components['views.groups.GroupRoomList'] = views$groups$GroupRoomList);
import views$groups$GroupRoomTile from './components/views/groups/GroupRoomTile';
views$groups$GroupRoomTile && (components['views.groups.GroupRoomTile'] = views$groups$GroupRoomTile);
import views$groups$GroupTile from './components/views/groups/GroupTile';
views$groups$GroupTile && (components['views.groups.GroupTile'] = views$groups$GroupTile);
import views$groups$GroupUserSettings from './components/views/groups/GroupUserSettings';
views$groups$GroupUserSettings && (components['views.groups.GroupUserSettings'] = views$groups$GroupUserSettings);
import views$messages$DateSeparator from './components/views/messages/DateSeparator';
views$messages$DateSeparator && (components['views.messages.DateSeparator'] = views$messages$DateSeparator);
import views$messages$MAudioBody from './components/views/messages/MAudioBody';
views$messages$MAudioBody && (components['views.messages.MAudioBody'] = views$messages$MAudioBody);
import views$messages$MFileBody from './components/views/messages/MFileBody';
views$messages$MFileBody && (components['views.messages.MFileBody'] = views$messages$MFileBody);
import views$messages$MImageBody from './components/views/messages/MImageBody';
views$messages$MImageBody && (components['views.messages.MImageBody'] = views$messages$MImageBody);
import views$messages$MStickerBody from './components/views/messages/MStickerBody';
views$messages$MStickerBody && (components['views.messages.MStickerBody'] = views$messages$MStickerBody);
import views$messages$MVideoBody from './components/views/messages/MVideoBody';
views$messages$MVideoBody && (components['views.messages.MVideoBody'] = views$messages$MVideoBody);
import views$messages$MessageEvent from './components/views/messages/MessageEvent';
views$messages$MessageEvent && (components['views.messages.MessageEvent'] = views$messages$MessageEvent);
import views$messages$MessageTimestamp from './components/views/messages/MessageTimestamp';
views$messages$MessageTimestamp && (components['views.messages.MessageTimestamp'] = views$messages$MessageTimestamp);
import views$messages$RoomAvatarEvent from './components/views/messages/RoomAvatarEvent';
views$messages$RoomAvatarEvent && (components['views.messages.RoomAvatarEvent'] = views$messages$RoomAvatarEvent);
import views$messages$RoomCreate from './components/views/messages/RoomCreate';
views$messages$RoomCreate && (components['views.messages.RoomCreate'] = views$messages$RoomCreate);
import views$messages$SenderProfile from './components/views/messages/SenderProfile';
views$messages$SenderProfile && (components['views.messages.SenderProfile'] = views$messages$SenderProfile);
import views$messages$TextualBody from './components/views/messages/TextualBody';
views$messages$TextualBody && (components['views.messages.TextualBody'] = views$messages$TextualBody);
import views$messages$TextualEvent from './components/views/messages/TextualEvent';
views$messages$TextualEvent && (components['views.messages.TextualEvent'] = views$messages$TextualEvent);
import views$messages$UnknownBody from './components/views/messages/UnknownBody';
views$messages$UnknownBody && (components['views.messages.UnknownBody'] = views$messages$UnknownBody);
import views$right_panel$GroupHeaderButtons from './components/views/right_panel/GroupHeaderButtons';
views$right_panel$GroupHeaderButtons && (components['views.right_panel.GroupHeaderButtons'] = views$right_panel$GroupHeaderButtons);
import views$right_panel$HeaderButton from './components/views/right_panel/HeaderButton';
views$right_panel$HeaderButton && (components['views.right_panel.HeaderButton'] = views$right_panel$HeaderButton);
import views$right_panel$HeaderButtons from './components/views/right_panel/HeaderButtons';
views$right_panel$HeaderButtons && (components['views.right_panel.HeaderButtons'] = views$right_panel$HeaderButtons);
import views$right_panel$RoomHeaderButtons from './components/views/right_panel/RoomHeaderButtons';
views$right_panel$RoomHeaderButtons && (components['views.right_panel.RoomHeaderButtons'] = views$right_panel$RoomHeaderButtons);
import views$room_settings$AliasSettings from './components/views/room_settings/AliasSettings';
views$room_settings$AliasSettings && (components['views.room_settings.AliasSettings'] = views$room_settings$AliasSettings);
import views$room_settings$ColorSettings from './components/views/room_settings/ColorSettings';
views$room_settings$ColorSettings && (components['views.room_settings.ColorSettings'] = views$room_settings$ColorSettings);
import views$room_settings$RelatedGroupSettings from './components/views/room_settings/RelatedGroupSettings';
views$room_settings$RelatedGroupSettings && (components['views.room_settings.RelatedGroupSettings'] = views$room_settings$RelatedGroupSettings);
import views$room_settings$RoomProfileSettings from './components/views/room_settings/RoomProfileSettings';
views$room_settings$RoomProfileSettings && (components['views.room_settings.RoomProfileSettings'] = views$room_settings$RoomProfileSettings);
import views$room_settings$UrlPreviewSettings from './components/views/room_settings/UrlPreviewSettings';
views$room_settings$UrlPreviewSettings && (components['views.room_settings.UrlPreviewSettings'] = views$room_settings$UrlPreviewSettings);
import views$rooms$AppsDrawer from './components/views/rooms/AppsDrawer';
views$rooms$AppsDrawer && (components['views.rooms.AppsDrawer'] = views$rooms$AppsDrawer);
import views$rooms$Autocomplete from './components/views/rooms/Autocomplete';
views$rooms$Autocomplete && (components['views.rooms.Autocomplete'] = views$rooms$Autocomplete);
import views$rooms$AuxPanel from './components/views/rooms/AuxPanel';
views$rooms$AuxPanel && (components['views.rooms.AuxPanel'] = views$rooms$AuxPanel);
import views$rooms$E2EIcon from './components/views/rooms/E2EIcon';
views$rooms$E2EIcon && (components['views.rooms.E2EIcon'] = views$rooms$E2EIcon);
import views$rooms$EntityTile from './components/views/rooms/EntityTile';
views$rooms$EntityTile && (components['views.rooms.EntityTile'] = views$rooms$EntityTile);
import views$rooms$EventTile from './components/views/rooms/EventTile';
views$rooms$EventTile && (components['views.rooms.EventTile'] = views$rooms$EventTile);
import views$rooms$ForwardMessage from './components/views/rooms/ForwardMessage';
views$rooms$ForwardMessage && (components['views.rooms.ForwardMessage'] = views$rooms$ForwardMessage);
import views$rooms$JumpToBottomButton from './components/views/rooms/JumpToBottomButton';
views$rooms$JumpToBottomButton && (components['views.rooms.JumpToBottomButton'] = views$rooms$JumpToBottomButton);
import views$rooms$LinkPreviewWidget from './components/views/rooms/LinkPreviewWidget';
views$rooms$LinkPreviewWidget && (components['views.rooms.LinkPreviewWidget'] = views$rooms$LinkPreviewWidget);
import views$rooms$MemberDeviceInfo from './components/views/rooms/MemberDeviceInfo';
views$rooms$MemberDeviceInfo && (components['views.rooms.MemberDeviceInfo'] = views$rooms$MemberDeviceInfo);
import views$rooms$MemberInfo from './components/views/rooms/MemberInfo';
views$rooms$MemberInfo && (components['views.rooms.MemberInfo'] = views$rooms$MemberInfo);
import views$rooms$MemberList from './components/views/rooms/MemberList';
views$rooms$MemberList && (components['views.rooms.MemberList'] = views$rooms$MemberList);
import views$rooms$MemberTile from './components/views/rooms/MemberTile';
views$rooms$MemberTile && (components['views.rooms.MemberTile'] = views$rooms$MemberTile);
import views$rooms$MessageComposer from './components/views/rooms/MessageComposer';
views$rooms$MessageComposer && (components['views.rooms.MessageComposer'] = views$rooms$MessageComposer);
import views$rooms$MessageComposerInput from './components/views/rooms/MessageComposerInput';
views$rooms$MessageComposerInput && (components['views.rooms.MessageComposerInput'] = views$rooms$MessageComposerInput);
import views$rooms$PinnedEventTile from './components/views/rooms/PinnedEventTile';
views$rooms$PinnedEventTile && (components['views.rooms.PinnedEventTile'] = views$rooms$PinnedEventTile);
import views$rooms$PinnedEventsPanel from './components/views/rooms/PinnedEventsPanel';
views$rooms$PinnedEventsPanel && (components['views.rooms.PinnedEventsPanel'] = views$rooms$PinnedEventsPanel);
import views$rooms$PresenceLabel from './components/views/rooms/PresenceLabel';
views$rooms$PresenceLabel && (components['views.rooms.PresenceLabel'] = views$rooms$PresenceLabel);
import views$rooms$ReadReceiptMarker from './components/views/rooms/ReadReceiptMarker';
views$rooms$ReadReceiptMarker && (components['views.rooms.ReadReceiptMarker'] = views$rooms$ReadReceiptMarker);
import views$rooms$ReplyPreview from './components/views/rooms/ReplyPreview';
views$rooms$ReplyPreview && (components['views.rooms.ReplyPreview'] = views$rooms$ReplyPreview);
import views$rooms$RoomBreadcrumbs from './components/views/rooms/RoomBreadcrumbs';
views$rooms$RoomBreadcrumbs && (components['views.rooms.RoomBreadcrumbs'] = views$rooms$RoomBreadcrumbs);
import views$rooms$RoomDetailList from './components/views/rooms/RoomDetailList';
views$rooms$RoomDetailList && (components['views.rooms.RoomDetailList'] = views$rooms$RoomDetailList);
import views$rooms$RoomDetailRow from './components/views/rooms/RoomDetailRow';
views$rooms$RoomDetailRow && (components['views.rooms.RoomDetailRow'] = views$rooms$RoomDetailRow);
import views$rooms$RoomDropTarget from './components/views/rooms/RoomDropTarget';
views$rooms$RoomDropTarget && (components['views.rooms.RoomDropTarget'] = views$rooms$RoomDropTarget);
import views$rooms$RoomHeader from './components/views/rooms/RoomHeader';
views$rooms$RoomHeader && (components['views.rooms.RoomHeader'] = views$rooms$RoomHeader);
import views$rooms$RoomList from './components/views/rooms/RoomList';
views$rooms$RoomList && (components['views.rooms.RoomList'] = views$rooms$RoomList);
import views$rooms$RoomNameEditor from './components/views/rooms/RoomNameEditor';
views$rooms$RoomNameEditor && (components['views.rooms.RoomNameEditor'] = views$rooms$RoomNameEditor);
import views$rooms$RoomPreviewBar from './components/views/rooms/RoomPreviewBar';
views$rooms$RoomPreviewBar && (components['views.rooms.RoomPreviewBar'] = views$rooms$RoomPreviewBar);
import views$rooms$RoomRecoveryReminder from './components/views/rooms/RoomRecoveryReminder';
views$rooms$RoomRecoveryReminder && (components['views.rooms.RoomRecoveryReminder'] = views$rooms$RoomRecoveryReminder);
import views$rooms$RoomTile from './components/views/rooms/RoomTile';
views$rooms$RoomTile && (components['views.rooms.RoomTile'] = views$rooms$RoomTile);
import views$rooms$RoomTooltip from './components/views/rooms/RoomTooltip';
views$rooms$RoomTooltip && (components['views.rooms.RoomTooltip'] = views$rooms$RoomTooltip);
import views$rooms$RoomTopicEditor from './components/views/rooms/RoomTopicEditor';
views$rooms$RoomTopicEditor && (components['views.rooms.RoomTopicEditor'] = views$rooms$RoomTopicEditor);
import views$rooms$RoomUpgradeWarningBar from './components/views/rooms/RoomUpgradeWarningBar';
views$rooms$RoomUpgradeWarningBar && (components['views.rooms.RoomUpgradeWarningBar'] = views$rooms$RoomUpgradeWarningBar);
import views$rooms$SearchBar from './components/views/rooms/SearchBar';
views$rooms$SearchBar && (components['views.rooms.SearchBar'] = views$rooms$SearchBar);
import views$rooms$SearchResultTile from './components/views/rooms/SearchResultTile';
views$rooms$SearchResultTile && (components['views.rooms.SearchResultTile'] = views$rooms$SearchResultTile);
import views$rooms$SearchableEntityList from './components/views/rooms/SearchableEntityList';
views$rooms$SearchableEntityList && (components['views.rooms.SearchableEntityList'] = views$rooms$SearchableEntityList);
import views$rooms$SimpleRoomHeader from './components/views/rooms/SimpleRoomHeader';
views$rooms$SimpleRoomHeader && (components['views.rooms.SimpleRoomHeader'] = views$rooms$SimpleRoomHeader);
import views$rooms$Stickerpicker from './components/views/rooms/Stickerpicker';
views$rooms$Stickerpicker && (components['views.rooms.Stickerpicker'] = views$rooms$Stickerpicker);
import views$rooms$TopUnreadMessagesBar from './components/views/rooms/TopUnreadMessagesBar';
views$rooms$TopUnreadMessagesBar && (components['views.rooms.TopUnreadMessagesBar'] = views$rooms$TopUnreadMessagesBar);
import views$rooms$UserTile from './components/views/rooms/UserTile';
views$rooms$UserTile && (components['views.rooms.UserTile'] = views$rooms$UserTile);
import views$rooms$WhoIsTypingTile from './components/views/rooms/WhoIsTypingTile';
views$rooms$WhoIsTypingTile && (components['views.rooms.WhoIsTypingTile'] = views$rooms$WhoIsTypingTile);
import views$settings$ChangeAvatar from './components/views/settings/ChangeAvatar';
views$settings$ChangeAvatar && (components['views.settings.ChangeAvatar'] = views$settings$ChangeAvatar);
import views$settings$ChangeDisplayName from './components/views/settings/ChangeDisplayName';
views$settings$ChangeDisplayName && (components['views.settings.ChangeDisplayName'] = views$settings$ChangeDisplayName);
import views$settings$ChangePassword from './components/views/settings/ChangePassword';
views$settings$ChangePassword && (components['views.settings.ChangePassword'] = views$settings$ChangePassword);
import views$settings$DevicesPanel from './components/views/settings/DevicesPanel';
views$settings$DevicesPanel && (components['views.settings.DevicesPanel'] = views$settings$DevicesPanel);
import views$settings$DevicesPanelEntry from './components/views/settings/DevicesPanelEntry';
views$settings$DevicesPanelEntry && (components['views.settings.DevicesPanelEntry'] = views$settings$DevicesPanelEntry);
import views$settings$EmailAddresses from './components/views/settings/EmailAddresses';
views$settings$EmailAddresses && (components['views.settings.EmailAddresses'] = views$settings$EmailAddresses);
import views$settings$EnableNotificationsButton from './components/views/settings/EnableNotificationsButton';
views$settings$EnableNotificationsButton && (components['views.settings.EnableNotificationsButton'] = views$settings$EnableNotificationsButton);
import views$settings$IntegrationsManager from './components/views/settings/IntegrationsManager';
views$settings$IntegrationsManager && (components['views.settings.IntegrationsManager'] = views$settings$IntegrationsManager);
import views$settings$KeyBackupPanel from './components/views/settings/KeyBackupPanel';
views$settings$KeyBackupPanel && (components['views.settings.KeyBackupPanel'] = views$settings$KeyBackupPanel);
import views$settings$Notifications from './components/views/settings/Notifications';
views$settings$Notifications && (components['views.settings.Notifications'] = views$settings$Notifications);
import views$settings$PhoneNumbers from './components/views/settings/PhoneNumbers';
views$settings$PhoneNumbers && (components['views.settings.PhoneNumbers'] = views$settings$PhoneNumbers);
import views$settings$ProfileSettings from './components/views/settings/ProfileSettings';
views$settings$ProfileSettings && (components['views.settings.ProfileSettings'] = views$settings$ProfileSettings);
import views$settings$tabs$AdvancedRoomSettingsTab from './components/views/settings/tabs/AdvancedRoomSettingsTab';
views$settings$tabs$AdvancedRoomSettingsTab && (components['views.settings.tabs.AdvancedRoomSettingsTab'] = views$settings$tabs$AdvancedRoomSettingsTab);
import views$settings$tabs$FlairSettingsTab from './components/views/settings/tabs/FlairSettingsTab';
views$settings$tabs$FlairSettingsTab && (components['views.settings.tabs.FlairSettingsTab'] = views$settings$tabs$FlairSettingsTab);
import views$settings$tabs$GeneralRoomSettingsTab from './components/views/settings/tabs/GeneralRoomSettingsTab';
views$settings$tabs$GeneralRoomSettingsTab && (components['views.settings.tabs.GeneralRoomSettingsTab'] = views$settings$tabs$GeneralRoomSettingsTab);
import views$settings$tabs$GeneralUserSettingsTab from './components/views/settings/tabs/GeneralUserSettingsTab';
views$settings$tabs$GeneralUserSettingsTab && (components['views.settings.tabs.GeneralUserSettingsTab'] = views$settings$tabs$GeneralUserSettingsTab);
import views$settings$tabs$HelpSettingsTab from './components/views/settings/tabs/HelpSettingsTab';
views$settings$tabs$HelpSettingsTab && (components['views.settings.tabs.HelpSettingsTab'] = views$settings$tabs$HelpSettingsTab);
import views$settings$tabs$LabsSettingsTab from './components/views/settings/tabs/LabsSettingsTab';
views$settings$tabs$LabsSettingsTab && (components['views.settings.tabs.LabsSettingsTab'] = views$settings$tabs$LabsSettingsTab);
import views$settings$tabs$NotificationSettingsTab from './components/views/settings/tabs/NotificationSettingsTab';
views$settings$tabs$NotificationSettingsTab && (components['views.settings.tabs.NotificationSettingsTab'] = views$settings$tabs$NotificationSettingsTab);
import views$settings$tabs$PreferencesSettingsTab from './components/views/settings/tabs/PreferencesSettingsTab';
views$settings$tabs$PreferencesSettingsTab && (components['views.settings.tabs.PreferencesSettingsTab'] = views$settings$tabs$PreferencesSettingsTab);
import views$settings$tabs$RolesRoomSettingsTab from './components/views/settings/tabs/RolesRoomSettingsTab';
views$settings$tabs$RolesRoomSettingsTab && (components['views.settings.tabs.RolesRoomSettingsTab'] = views$settings$tabs$RolesRoomSettingsTab);
import views$settings$tabs$SecurityRoomSettingsTab from './components/views/settings/tabs/SecurityRoomSettingsTab';
views$settings$tabs$SecurityRoomSettingsTab && (components['views.settings.tabs.SecurityRoomSettingsTab'] = views$settings$tabs$SecurityRoomSettingsTab);
import views$settings$tabs$SecuritySettingsTab from './components/views/settings/tabs/SecuritySettingsTab';
views$settings$tabs$SecuritySettingsTab && (components['views.settings.tabs.SecuritySettingsTab'] = views$settings$tabs$SecuritySettingsTab);
import views$settings$tabs$VoiceSettingsTab from './components/views/settings/tabs/VoiceSettingsTab';
views$settings$tabs$VoiceSettingsTab && (components['views.settings.tabs.VoiceSettingsTab'] = views$settings$tabs$VoiceSettingsTab);
import views$verification$VerificationCancelled from './components/views/verification/VerificationCancelled';
views$verification$VerificationCancelled && (components['views.verification.VerificationCancelled'] = views$verification$VerificationCancelled);
import views$verification$VerificationComplete from './components/views/verification/VerificationComplete';
views$verification$VerificationComplete && (components['views.verification.VerificationComplete'] = views$verification$VerificationComplete);
import views$verification$VerificationShowSas from './components/views/verification/VerificationShowSas';
views$verification$VerificationShowSas && (components['views.verification.VerificationShowSas'] = views$verification$VerificationShowSas);
import views$voip$CallPreview from './components/views/voip/CallPreview';
views$voip$CallPreview && (components['views.voip.CallPreview'] = views$voip$CallPreview);
import views$voip$CallView from './components/views/voip/CallView';
views$voip$CallView && (components['views.voip.CallView'] = views$voip$CallView);
import views$voip$IncomingCallBox from './components/views/voip/IncomingCallBox';
views$voip$IncomingCallBox && (components['views.voip.IncomingCallBox'] = views$voip$IncomingCallBox);
import views$voip$VideoFeed from './components/views/voip/VideoFeed';
views$voip$VideoFeed && (components['views.voip.VideoFeed'] = views$voip$VideoFeed);
import views$voip$VideoView from './components/views/voip/VideoView';
views$voip$VideoView && (components['views.voip.VideoView'] = views$voip$VideoView);
export {components};
