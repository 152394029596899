'use strict';

import React from 'react';
import classNames from 'classnames';

import SdkConfig from 'matrix-react-sdk/lib/SdkConfig';
import sdk from 'matrix-react-sdk/lib/index';
import MatrixClientPeg from 'matrix-react-sdk/lib/MatrixClientPeg';
import PasswordLogin from 'matrix-react-sdk/lib/components/views/auth/PasswordLogin';
import { _t } from 'matrix-react-sdk/lib/languageHandler';

import ApiSession from '../../../ApiSession';

require('./GgPasswordLogin.scss');


export default class GgPasswordLogin extends PasswordLogin {
  static replaces = 'PasswordLogin';


  constructor(props) {
  	super(props);

  	this.state.loginType = PasswordLogin.LOGIN_FIELD_EMAIL;
    this.state.mfaScreen = false;
    ApiSession.instance.clearToken();

    this.loginScreen = this.loginScreen.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onSubmitMfaForm = this.onSubmitMfaForm.bind(this);
    this._onMfaFactorChange = this._onMfaFactorChange.bind(this);
    this._onMfaPassCodeChange = this._onMfaPassCodeChange.bind(this);
    this.sendMfaChallenge = this.sendMfaChallenge.bind(this);
  }


  async onSubmitForm(ev) {
    ev.preventDefault();
    this.props.onError(null);

    if ( !this.state.username ) {
      let error = _t('The email field must not be blank.');
      this.props.onError(error);
      return;
    }

    if ( !this.state.password ) {
      this.props.onError(_t('The password field must not be blank.'));
      return;
    }

    const api_url = SdkConfig.get()['gg_api_url'];

    let res = await fetch(api_url +'/authentication/webLogin', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: this.state.username,
        password: this.state.password,
      }),
    });

    let data = null;
    try { data = await res.json(); } catch(e){};

    if ( res.ok ) {
      ApiSession.instance.intercept(res);

      if ( data.roles.includes('mfa_required_user') ) {
        let factors = [];
        Object.keys(data.mfa.factors).forEach((factor)=>{
          Object.keys(data.mfa.factors[factor]).forEach((provider)=>{
            let val = MFA_TEMPLATE[factor +'_'+ provider];
            if ( val ) factors.push(val);
          });
        });
        // factors.push(MFA_TEMPLATE.sms_okta); // for test

        await new Promise((resolve)=>this.setState({
            mfaScreen: true,
            mfaFactors: factors,
            factor: factors[0],
            challengeSent:true,
          }, resolve));
        if ( factors.length == 1 && factors[0].id == 'sms_okta' ) {
          await this.sendMfaChallenge();
        }

      } else {
        this._getUserAndLogin(data._id, res.headers.get('Authorization'));
      }
    } else {
      (await this._handleError(data)) || this.props.onError(_t(res.status === 401 ? 'Incorrect username and/or password.' : 'Server unavailable'));
    }
  }

  async onSubmitMfaForm(ev) {
    ev.preventDefault();

    if ( !this.state.passcode ) {
      this.props.onError('Passcode is required');
      return;
    }

    const api_url = SdkConfig.get()['gg_api_url'];
    const factor = this.state.factor;

    let res = await fetch(api_url +'/authentication/mfa/verify', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': ApiSession.instance.getToken(),
      },
      body: JSON.stringify({
        user_id: +ApiSession.instance.getUserID(),
        type: factor.type,
        provider: factor.provider || undefined,
        pin_code: this.state.passcode,
      }),
    });

    let data = null;
    try{ data = await res.json(); } catch(e){}
    if ( res.ok ) {
      ApiSession.instance.intercept(res);
      await this._getUserAndLogin(data._id, ApiSession.instance.getToken());
    } else {
      if ( res.status == 401 && (!data || (!data.error_code && !data.errors)) ) {
        this.props.onError('Session has expired');
        this.loginScreen();
      } else {
        (await this._handleError(data)) || this.props.onError('Unknown error');
      }
    }
  }

  loginScreen(){
    this.setState({ mfaScreen:false, mfaFactors:null });
    ApiSession.instance.clearToken();
  }


  async _getUserAndLogin(id, authHeader){
    const api_url = SdkConfig.get()['gg_api_url'];

    let res = await fetch(api_url +'/users/'+ id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': authHeader,
      },
    });

    if ( res.ok ) {
      let data = await res.json();
      ApiSession.instance.intercept(res);
      ApiSession.instance.user = data;
      const token = res.headers.get('Authorization');
      this.props.onSubmit('gg'+ data._id, null, null, token.substr(7));
    } else if ( data && ERRORS[data.error_code] ) {
      this.props.onError(ERRORS[data.error_code]);
    } else {
      this.props.onError(_t(res.status === 401 ? 'Incorrect username and/or password.' : 'Server unavailable'));
    }
  }

  render(){
    if ( this.state.mfaScreen )
      return this._renderMfa();
    else
      return this._renderLogin();
  }

  _renderLogin() {
    const pwFieldClass = classNames({
      mx_Login_field: true,
      error: this.props.loginIncorrect && !this.isLoginEmpty(), // only error password if error isn't top field
    });
    const loginField = this.renderLoginField(this.state.loginType);

    const forgot_console_url = SdkConfig.get()['gg_console_url'] +'/forgot-password';

    return (
      <div>
        <form onSubmit={this.onSubmitForm}>
          { loginField }
          <input className={pwFieldClass} ref={(e) => {this._passwordField = e;}} type="password"
              name="password"
              value={this.state.password} onChange={this.onPasswordChanged}
              placeholder={_t('Password')}
          />
          <br />
          <a className="mx_Login_forgot" href={forgot_console_url} target="_blank">{_t('Forgot Password?')}</a>
          <input className="mx_Login_submit"
              type="submit"
              value={_t('Login')}
              disabled={this.props.disableSubmit}
          />
        </form>
      </div>
    );
  }

  _renderMfa() {
    const Dropdown = sdk.getComponent('elements.Dropdown');
    const options = this.state.mfaFactors.map((factor)=>(
      <div key={factor.id}>{ factor.label }</div>
    ));

    let value = this.state.factor.id;
    let resendSms = value == 'sms_okta' ? <a onClick={this.sendMfaChallenge} href="javascript:void(0)">Resend Text</a> : null;

    return (
      <div>
        <form onSubmit={this.onSubmitMfaForm} autoComplete="off">
          <Dropdown className="mx_Mfa_factor flex" onOptionChange={this._onMfaFactorChange} value={value} 
            disabled={this.props.disableSubmit}
          >
            { options }
          </Dropdown>
          <br />
          <br />
          { this.state.factor && this.state.challengeSent ? this.state.factor.desc : undefined }
          <br />
          <input className="mx_Mfa_passcode" ref={(e) => {this._passcodeField = e;}}
            onChange={this._onMfaPassCodeChange}
            type="text" name="passcode" placeholder="Enter code" autoComplete="off"
            data-lpignore="true"
          />
          <input className="mx_Login_submit mx_Login_mfa_submit"
              type="submit"
              value="Verify"
              disabled={this.props.disableSubmit}
          />
          { resendSms }
        </form>
      </div>
    );
  }


  async _onMfaFactorChange(id){
    await new Promise((resolve)=>this.setState({ factor: MFA_TEMPLATE[id] }, resolve));
    this.props.onError(undefined);

    console.log('factor', id, this.state.factor);
    if ( id == 'sms_okta' ) {
      await this.sendMfaChallenge();
    } else {
      this.setState({ challengeSent: true });
    }
  }
  _onMfaPassCodeChange(ev){
    this.setState({passcode: ev.target.value});
    this.props.onError(undefined);
  }


  async sendMfaChallenge(){
    this.props.onError(undefined);
    await new Promise((resolve)=>this.setState({ challengeSent:false }, resolve));

    const factor = this.state.factor;
    const api_url = SdkConfig.get()['gg_api_url'];
    const payload = {
      user_id: +ApiSession.instance.getUserID(),
      type: factor.type,
      provider: factor.provider || undefined,
    };
    console.log('payload', payload);

    let res = await fetch(api_url +'/authentication/mfa/challenge', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': ApiSession.instance.getToken(),
      },
      body: JSON.stringify(payload),
    });

    let data = null;
    try{ data = await res.json(); } catch(e){}
    if ( res.ok ) {
      ApiSession.instance.intercept(res);
      this.setState({ challengeSent: true });
      return true;
    }
    (await this._handleError(data)) || this.props.onError('Unknown error');

    return false;
  }

  async _handleError(data){
    if ( data && data.error_code && ERRORS[data.error_code] ) {
      this.props.onError(ERRORS[data.error_code]);
      return true;
    } else if ( data && data.errors ) {
      let errors = Object.values(data.errors);
      if ( errors.length > 0 && errors[0].code && ERRORS[errors[0].code] ) {
        this.props.onError(ERRORS[errors[0].code]);
        return true;
      }
    }
    return false;
  }


}



const MFA_TEMPLATE = {
  totp_okta: {
    id: 'totp_okta',
    type: 'totp',
    provider: 'okta',
    label: 'Okta Verify',
    desc: 'Enter your Okta Verify passcode',
    // img: require('../common/images/okta.png'),
  },
  totp_google: {
    id: 'totp_google',
    type: 'totp',
    provider: 'google',
    label: 'Google Authenticator',
    desc: 'Enter your Google Authenticator passcode',
    // img: require('../common/images/googleAuth.png'),
  },
  sms_okta: {
    id: 'sms_okta',
    type: 'sms',
    provider: 'okta',
    label: 'SMS Verification',
    desc: 'A text message with verification code was just sent to your registered number',
    icon: 'fas fa-sms',
  },
}

const ERRORS = {
  user_does_not_have_mfa_enrolled: 'We could not find your information in Okta. Either you have not been set up or your account had been locked. Please contact your Okta administrator.',
  user_does_not_have_mfa_factors_enrolled: 'We could not find your information in Okta. Either you have not been set up or your account had been locked. Please contact your Okta administrator.',
  mfa_factor_not_enrolled: 'Multi-factor authentication type is not enrolled for the user.',
  mfa_factor_unknown_result: 'Multi-factor authentication challenge/verification failed.',
  mfa_factor_challenge_limit_reached: 'An SMS message was recently sent. Please wait 30 seconds before trying again.',
  mfa_factor_verify_failed: 'The passcode/answer is invalid.',
  mfa_factor_type_provider_not_supported: 'Invalid request.',
}
