'use strict';

import ApiSession from '../../ApiSession';
import MatrixChat from 'matrix-react-sdk/lib/components/structures/MatrixChat';
import MatrixClientPeg from 'matrix-react-sdk/lib/MatrixClientPeg';
import dis from 'matrix-react-sdk/lib/dispatcher';


export default class GgMatrixChat extends MatrixChat {
  static replaces = 'MatrixChat';

  constructor(props) {
    super(props);

    // this is an ugle implementation of extending from old react class to es6 class
    // https://stackoverflow.com/questions/35909476/how-to-extend-react-component-class-created-via-react-createclass
    this._viewWelcome = this._onLoggedOut;
		// this._startRegistration = this._onLoggedOut;
		
		this._onAction = this._onAction.bind(this);
	}
	
	componentDidMount(){
		MatrixChat.prototype.componentDidMount.call(this);

		this._onAction.dereg = dis.register(this._onAction);
		ApiSession.instance.init();
	}
	componentWillUnmount(){
		MatrixChat.prototype.componentWillUnmount.call(this);

		dis.unregister(this._onAction.dereg);
		ApiSession.instance.clearToken();
	}

	_onAction(payload){
		if ( payload.action === 'on_logged_in' ) {
			ApiSession.instance.init();
		} else
		if ( payload.action === 'user_activity' ) {
			ApiSession.instance.checkToken();
		} else
		if ( payload.action === 'on_logged_out' ) {
			ApiSession.instance.clearToken();
		}
	}

}
