'use strict';

import React from 'react';
import dis from 'matrix-react-sdk/lib/dispatcher';
import sdk from 'matrix-react-sdk/lib/index';
import SdkConfig from 'matrix-react-sdk/lib/SdkConfig';
import MatrixClientPeg from 'matrix-react-sdk/lib/MatrixClientPeg';
import {Promise} from 'bluebird';
import sanitizeHtml from 'sanitize-html';

import ApiSession from '../../../ApiSession';
import GgRightPanel from '../../structures/GgRightPanel';

require('./GgTasksList.scss');

const VALID_TAGS = 'a,strong,b,blockquote,br,div,h1,h2,h3,h4,h5,h6,hr,em,i,img,li,ol,p,pre,s,del,table,tbody,td,tfoot,th,thead,tr,u,ul'.split(',');
const TASKPROG_INIT_RATE = 0.1;
const TASKPROG_FACTOR = 0.25;


export default class GgTasksList extends React.PureComponent {
  constructor(props){
    super(props);

    this.state = {
      loading: true,
      tasks: [],
      tags: [],
      colleges: [],
    };

    this.refresh = this.refresh.bind(this);
    this._getChildren = this._getChildren.bind(this);
    this._getChildCount = this._getChildCount.bind(this);

    this._onFilterChange = this._onFilterChange.bind(this);
    this._getFilterDisplay = this._getFilterDisplay.bind(this);

    this._onSelect = this._onSelect.bind(this);
    this._onSendLink = this._onSendLink.bind(this);
  }

  componentDidMount(){
    this.refresh();
  }

  refresh(){
    const cli = MatrixClientPeg.get();
    const room = cli.getRoom(this.props.roomId);
    const me = cli.getUserId();
    const members = Object.values(room.currentState.members)
      .filter(mem=>mem.membership == 'join' && mem.powerLevel != 100 && mem.userId != me);

    const api_url = SdkConfig.get()['gg_api_url'];
    const cfg = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': ApiSession.instance.getToken(),
      }
    };

    this.setState({error: null, loading: true});

    let tags, colleges, tasks;

    Promise.all([
      Promise.resolve(fetch(api_url +'/tags', cfg))
        .then(res=>res.ok && ApiSession.instance.intercept(res).json()
          .then(data=>{
            if ( Array.isArray(data) ) {
              data = data.filter(tag=>!tag.hide);
            }
            data.byId = {};
            data.forEach(tag=>data.byId[tag._id] = tag);
            return tags = data;
          })
        ),
      Promise.resolve(fetch(api_url+'/colleges', cfg))
        .then(res=>res.ok && ApiSession.instance.intercept(res).json()
          .then(data=>{
            data.byId = {};
            data.forEach(college=>data.byId[college._id] = college);
            return colleges = data;
          })
        ),
      Promise.any(members.map(member=>{
        let id = member && member.userId.replace(/^@gg(\d+).*$/, '$1');
        return Promise.resolve(fetch(`${api_url}/users/${id}`, cfg))
          .then(res=>res.ok && ApiSession.instance.intercept(res).json())
          .then(user=>{
            if ( user.roles && user.roles.student ) return id
            throw null;
          })
      }))
        .then(studentID=>
          Promise.resolve(fetch(`${api_url}/contentDirectory/student/${studentID}?released=true&published=true`, cfg))
            .then(res=>res.ok && ApiSession.instance.intercept(res).json())
        )
        .then(data=>{
          tasks = data.map(item=>{
            let cd = item.content_directory || {};
            if ( item.content_directory_type == 'flow' )
              cd = item.show_in_content_directory.content_directory;

            item.title = cd.title;
            item.framing_message = cd.framing_message;
            item.image_url = cd.image_url;
            item.background_color = cd.background_color;
            item.tags = cd.tags;
            
            if ( item.background_color ) {
              let [r, g, b] = item.background_color.match(/\w\w/g).map(x => parseInt(x, 16));
              item.secondary_color = `rgba(${r},${g},${b},0.25)`;
            } else {
              if ( item.content_directory_type == 'flow' ) {
                item.background_color = '#2AA575';
                item.secondary_color = '#D4EDE3';
              }
            }
            
            if ( item.owner_college_id != 166 ) {
              item.content_owner = colleges.byId[item.owner_college_id].name;
            }

            if ( ! Array.isArray(item.steps) )
              item.steps = Object.values(item.steps || {});
            if ( ! Array.isArray(item.tags) )
              item.tags = Object.keys(item.tags || {});
            if ( item.description && item.description.content == 'text/html' ) {
              item.description.html = sanitizeHtml(item.description.formatted, {allowedTags: VALID_TAGS});
            }
            if ( item.video ) {
              if ( item.video.url )
                item.video_url = item.video.url.replace(' ', '%20');
              if ( item.video.thumbnail_url )
                item.video_thumbnail_url = item.video.thumbnail_url.replace(' ', '%20');
            }
            if ( item.image_url )
              item.image_url = item.image_url.replace(' ', '%20');

            return item;
          });
        })
    ])
    .then(()=>{
      this.setState({loading: false, error: null, tags, tasks});
    }, err=>{
      console.error(err);
      setTimeout(()=>this.setState({error: 'Unable to load resources from the server'}), 1000);
    })
  }


  _getChildren(start, end){
    return this.state.tasks
      .filter(item=>!this.state.filter || item.tags.includes(this.state.filter))
      .slice(start, end).map(item=>this.renderTaskCard(item));
  }

  _getChildCount(){
    return this.state.tasks.filter(item=>!this.state.filter || item.tags.includes(this.state.filter)).length;
  }


	_calcProgress(count){
		return 1 - (1 - TASKPROG_INIT_RATE) * Math.pow(1 - TASKPROG_FACTOR, count);
	}

  _onFilterChange(value){
    this.setState({filter: value && +value != -1 ? value : null});
  }
  _getFilterDisplay(value){
    if ( value && +value != -1 && this.state.tags && this.state.tags.byId ) {
      let tag = this.state.tags.byId[value];
      if ( tag && tag.name && tag.name.en_US ) {
        return tag.name.en_US;
      }
    }
    return <span className="placeholder">Filter by tag</span>;
  }

  _onSelect(item){
    this.setState({selected: item || null});
  }

  _onSendLink(item){
    const cli = MatrixClientPeg.get();
    const link = `[${item.title}|${item.transient.public_link}]`;
    cli.sendMessage(this.props.roomId, {msgtype:'m.text', body:link})
      .then(()=>{
        dis.dispatch({
          action: 'view_right_panel_phase',
          phase: GgRightPanel.Phase.TasksList,
          fromHeader: true,
        });
      });
  }


  render(){
    if ( this.state.selected ) {
      return this.renderSelected(this.state.selected);
    } else {
      return this.renderList();
    }
  }


  renderTaskCard(item){
    let progress, colleges;
    colleges = this.colleges
    if ( item.transient.progress > 0 ) {
      progress = (
        <div className="progress-wrap">
          <div className="bar" style={{width: item.transient.progress*100 +'%'}}></div>
        </div>
      );
    }

    return (
      <div className="mx_TasksList_Task" key={item.content_directory_type + item._id}>
        <div className="mx_TasksList_TaskCard cur_pointer layout-row layout-align-start-stretch" 
          onClick={ ()=>this._onSelect(item) }
          style={{backgroundColor: item.background_color}} 
        >
          <div className={'task_title_container layout-column layout-wrap '}>
            <h3 className={'task_title flex-nogrow '+ (item.steps.length ? 'has-step' : '')}>{ item.title }</h3>
            <span className="flex-nogrow flow-module-indicator"
            > 
              <img src={ item.content_directory_type == 'flow' ? require('../../../../res/img/flow-icon.svg') : '' } style={{ width: '15px', margin: '10px 0px 14px' }} />
            </span>
            <small className="task_desc flex-nogrow"
              style={{display: item.steps.length>0 || item.video_url ? 'block' : 'none' }}
            > 
              <img src={require('../../../../res/img/task_play.svg')} style={{ width: '7px', margin:'15px 5px 0px 0px' }} />
              { item.steps.length > 1 ? item.steps.length+' STEPS' : item.steps.length+' STEP' }
            </small>
            <i className="flex"></i>
          </div>
          <div className="thumbnail_wrap layout-column">
            <div className="thumbnail flex" style={{backgroundImage: item.image_url ? 'url('+ item.image_url +')' : ''}}></div>
          </div>
        </div>
        <div className="task_item_footer task_item_footer_list" style={{backgroundColor:item.secondary_color}}>
          <div className="recipient_college">
            <div>{ item.content_owner }</div>
          </div>
          <div className="icons">
            <img className="share" src={ item.content_directory_type == 'task' ? require('../../../../res/img/share-icon.svg') : '' }/>
            <img src={ require('../../../../res/img/save-icon.svg') }/>
          </div>
        </div>
      </div>
    );
  }

  renderList() {
    let content = <div />;

    if ( this.state.error ) {
      content = (
        <div className="mx_RoomView_messageListWrapper">
          <div className="mx_RoomView_empty">
            { this.state.error }
            <br />
            <input type="button" onClick={this.refresh} value="Retry" />
          </div>
        </div>
      );

    } else if ( this.state.loading ) {
      const Spinner = sdk.getComponent("elements.Spinner");
      content = <Spinner />;
    } else {
      const children = this._getChildren(0);

      if ( children.length > 0 ) {
        const GeminiScrollbarWrapper = sdk.getComponent("elements.GeminiScrollbarWrapper");
        content = (
            <GeminiScrollbarWrapper autoshow={true}>
              <div className="mx_TasksList_wrapper">
                { children }
              </div>
            </GeminiScrollbarWrapper>
        );
      } else {
        content = <div className="mx_RoomView_messageListWrapper"><div className="mx_RoomView_empty">No tasks found</div></div>;
      }
    }


    const Dropdown = sdk.getComponent('elements.Dropdown');
    const options = this.state.tags
      .filter(tag=> ! this.state.filterSearch || tag.name.en_US.toLowerCase().indexOf(this.state.filterSearch.toLowerCase()) > -1)
      .sort((a, b)=>a.name.en_US.toLowerCase() < b.name.en_US.toLowerCase() ? -1 : a.name.en_US.toLowerCase() > b.name.en_US.toLowerCase() ? 1 : 0)
      .map(tag=><div key={tag._id}>{ tag.name.en_US }</div>);
    options.unshift(<em key={-1}>- none -</em>);
    let value = this.state.filter || null;


    return (
      <div className="mx_TasksList flex layout-column">
        <div className="mx_TasksList_heading_wrap flex-nogrow layout-row">
          <div className="mx_TasksList_heading flex-nogrow">Tasks</div>
          <Dropdown className="mx_TasksList_filter flex" onOptionChange={this._onFilterChange} getShortOption={this._getFilterDisplay} value={value}>
            { options }
          </Dropdown>
        </div>
        { content }
      </div>
    );
  }

  renderSelected(item){
    const AccessibleButton = sdk.getComponent("elements.AccessibleButton");
    const GeminiScrollbarWrapper = sdk.getComponent("elements.GeminiScrollbarWrapper");

    let card = <div />;
    let description, tags, steps, points;
    console.log('task selected', item);

    if (item.description) {
      if (item.description.content == 'text/html') {
        description = <div className="task_text html_text" dangerouslySetInnerHTML={{ __html: item.description.html }} style={{ backgroundColor: item.secondary_color }} />
      } else {
        description = <div className="task_text" style={{ backgroundColor: item.secondary_color }}>{item.description.raw}</div>
      }
    }

    if ( item.framing_message ) {
      description = <div className="framing_msg">{item.framing_message}</div>
    }

    if ( item.video_url ) {
      card = (
        <div className="video_task">
          <div className="task_title_container" style={{ backgroundColor: item.background_color }}>
            <h3 className="taskTitle">{ item.title }</h3>
          </div>
          <div className="video_thumb layout-row layout-align-center-center" style={{backgroundImage: `url(${item.video_thumbnail_url})`}}>
            <img src={ require('../../../../res/img/task_play.svg') } style={{width:'32px'}}/>
          </div>
          <div className="task_item_footer task_item_footer_indiv" style={{ backgroundColor: item.secondary_color, borderRadius: item.description ? '0 0 0 0': '0 0 10px 10px'}}>
            <div className="recipient_college">
              <div>{ item.content_owner }</div>
            </div>
            <div className="icons">
              <img className="share" src={ require('../../../../res/img/share-icon.svg') }/>
              <img src={ require('../../../../res/img/save-icon.svg') }/>
            </div>
          </div>
          { description }
        </div>
      );
    } else {
      card = (
        <div>
          <div className="mx_TasksList_TaskCard micro_task layout-row layout-align-start-stretch" style={{ backgroundColor: item.background_color }}>
            <div className="task_title_container layout-column layout-align-center-start">
              <h3 className="task_title flex-nogrow">{ item.title }</h3>
              <img src={item.content_directory_type == 'flow' ? require('../../../../res/img/flow-icon.svg') : ''} style={{ width: '15px', margin: '10px 0px 14px' }} />
            </div>
          <div className="thumbnail_wrap layout-row layout-align-end-end">
            <span className="flex">
              <img className="thumbnail" src={ item.image_url } aria-hidden="false" />
            </span>
          </div>
          
        </div>
          <div className="task_item_footer task_item_footer_indiv" style={{ backgroundColor: item.secondary_color, borderRadius: item.description ? '0 0 0 0' : '0 0 10px 10px' }}>
            <div className="recipient_college">
              <div>{ item.content_owner }</div>
            </div>
            <div className="icons">
              <img className="share" src={ item.content_directory_type == 'task' ? require('../../../../res/img/share-icon.svg') : '' } />
              <img src={ require('../../../../res/img/save-icon.svg') }/>
            </div>
          </div>
          { description }
        </div>
      );
    }

    tags = item.tags
      .map(tagID=>this.state.tags.byId[tagID])
      .filter(tag=>tag && tag.name && tag.name.en_US);


    if ( item.content_directory_type == 'task' ) {
      steps = (
        <div className="steps" style={{borderTop: item.steps.length == 0 ? 'none' : '1px solid #7A99AC'}}>
          { item.steps.map((step, idx)=>{
            return (
              <div className="step layout-row" key={idx}>
                <span className="number flex-nogrow">{ idx+1 }</span>
                <span className="text flex">{ step.title }</span>
              </div>
            )
          }) }
        </div>
      );
    }

    if ( item.points ) {
      points = (
        <div className="step_points">
          <span>{ item.points } points</span>
        </div>
      )
    }

    return (
      <div className="mx_TasksList flex layout-column">
        <div className="mx_TasksList_heading_wrap layout-row">
          <AccessibleButton className="mx_TasksList_heading_back flex-nogrow" onClick={ ()=>this._onSelect(null) }>
            <img src={ require('../../../../res/img/MyC_BackArrow.svg') } />
          </AccessibleButton>
          <div className="mx_TasksList_heading center flex">{ tags.length > 0 ? tags[0].name.en_US : '' }</div>
        </div>
        <GeminiScrollbarWrapper autoshow={true}>
          <div className="mx_TasksList_wrapper">
            <div className="mx_TasksList_Task" key={item._id}>
              { card }
              { steps }
              <div className="mx_TasksList_TaskTags" style={{justifyContent: item.tags.length==0 && item.points ? 'flex-end' : 'flex-start'}}>
                <div className='tag' style={{display: item.tags.length>0 ? 'block' : 'none' }}>
                  { item.content_directory_type != 'flow' ? 
                    tags.map((tag, i, {length})=>{
                      if ( i+1 === length ) {
                        return <span key={tag._id}>{ tag.name.en_US }</span>
                      } else {
                        return <span key={tag._id}>{ tag.name.en_US } / </span>
                        
                      }
                    }) : ''
                  }
                </div>        
                {/* &nbsp; */}
                { points }
    
              </div>
            </div>
          </div>
        </GeminiScrollbarWrapper>
        <div className="mx_TasksList_footer layout-row layout-align-end-center">
          <AccessibleButton className="mx_AccessibleButton_kind_primary mx_AccessibleButton_hasKind" onClick={ ()=>this._onSendLink(item) }>
            Send Link
          </AccessibleButton>
        </div>
      </div>
    );
  }

}