/*
Copyright 2018 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

/*
 * THIS FILE IS AUTO-GENERATED
 * You can edit it you like, but your changes will be overwritten,
 * so you'd just be trying to swim upstream like a salmon.
 * You are not a salmon.
 */

let components = require('matrix-react-sdk/lib/component-index').components;
if (!components) {
    throw new Error("'matrix-react-sdk/lib/component-index' didn't export components");
}
import structures$GgGroupView from './components/structures/GgGroupView';
structures$GgGroupView && (components['structures.GgGroupView'] = structures$GgGroupView);
import structures$GgMatrixChat from './components/structures/GgMatrixChat';
structures$GgMatrixChat && (components['structures.GgMatrixChat'] = structures$GgMatrixChat);
import structures$GgRightPanel from './components/structures/GgRightPanel';
structures$GgRightPanel && (components['structures.GgRightPanel'] = structures$GgRightPanel);
import structures$GgTopLeftMenuButton from './components/structures/GgTopLeftMenuButton';
structures$GgTopLeftMenuButton && (components['structures.GgTopLeftMenuButton'] = structures$GgTopLeftMenuButton);
import structures$VectorEmbeddedPage from './components/structures/VectorEmbeddedPage';
structures$VectorEmbeddedPage && (components['structures.VectorEmbeddedPage'] = structures$VectorEmbeddedPage);
import views$auth$GgPasswordLogin from './components/views/auth/GgPasswordLogin';
views$auth$GgPasswordLogin && (components['views.auth.GgPasswordLogin'] = views$auth$GgPasswordLogin);
import views$auth$VectorAuthFooter from './components/views/auth/VectorAuthFooter';
views$auth$VectorAuthFooter && (components['views.auth.VectorAuthFooter'] = views$auth$VectorAuthFooter);
import views$auth$VectorAuthHeaderLogo from './components/views/auth/VectorAuthHeaderLogo';
views$auth$VectorAuthHeaderLogo && (components['views.auth.VectorAuthHeaderLogo'] = views$auth$VectorAuthHeaderLogo);
import views$auth$VectorAuthPage from './components/views/auth/VectorAuthPage';
views$auth$VectorAuthPage && (components['views.auth.VectorAuthPage'] = views$auth$VectorAuthPage);
import views$auth$VectorCustomServerDialog from './components/views/auth/VectorCustomServerDialog';
views$auth$VectorCustomServerDialog && (components['views.auth.VectorCustomServerDialog'] = views$auth$VectorCustomServerDialog);
import views$context_menus$GgTopLeftMenu from './components/views/context_menus/GgTopLeftMenu';
views$context_menus$GgTopLeftMenu && (components['views.context_menus.GgTopLeftMenu'] = views$context_menus$GgTopLeftMenu);
import views$right_panel$GgRoomHeaderButtons from './components/views/right_panel/GgRoomHeaderButtons';
views$right_panel$GgRoomHeaderButtons && (components['views.right_panel.GgRoomHeaderButtons'] = views$right_panel$GgRoomHeaderButtons);
import views$right_panel$GgTasksList from './components/views/right_panel/GgTasksList';
views$right_panel$GgTasksList && (components['views.right_panel.GgTasksList'] = views$right_panel$GgTasksList);
import views$rooms$GgMessageComposer from './components/views/rooms/GgMessageComposer';
views$rooms$GgMessageComposer && (components['views.rooms.GgMessageComposer'] = views$rooms$GgMessageComposer);
import views$rooms$GgMessageComposerInput from './components/views/rooms/GgMessageComposerInput';
views$rooms$GgMessageComposerInput && (components['views.rooms.GgMessageComposerInput'] = views$rooms$GgMessageComposerInput);
export {components};
